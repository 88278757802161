
import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Html5QrcodeScanner } from 'html5-qrcode';
import Select from "react-select"
import { Dots } from 'react-activity';
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/material/Autocomplete';
import * as url from "../../helpers/url_helper"
import moment from 'moment';
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import logoVSP from "../../assets/images/VUF-Logo-curve.svg";
import VUF_TEMP from "../../assets/images/VUF_temple.jpg";
import TEMP from "../../assets/images/temple.jpg";
import QRCode from "react-qr-code";

const RegisterEvent = (props) => {
    const { id, Show } = useParams();
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [mobileNo, setMobileNo] = useState(null);
    const [isValidMobileNo, setIsValidMobileNo] = useState(true);
    const [eventList, setEventList] = useState([]);
    const [bannerShow, setbannerShow] = useState("")
    const [eventUser, setEventUser] = useState(null);
    // const [event, setEvent] = useState("");
    const [eventId, setEventID] = useState("")
    const [userId, setUserID] = useState(null);
    const [mode, setMode] = useState(null);
    const [event, setEvent] = useState(null);
    const [locationDetails, setlocationDetails] = useState(null);
    const [countries, setcountries] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [districtList, setdistrictList] = useState([]);
    const [CityList, setCityList] = useState([]);

    const [selectedCity, setselectedCity] = useState(null);
    const [selectedCountry, setselectedCountry] = useState(null);
    const [selectedDistrict, setselectedDistrict] = useState(null);
    const [selectedState, setselectedState] = useState(null);
    const [attendedBoyChilds, setattendedBoyChilds] = useState(null);
    const [attendedGirlChilds, setattendedGirlChilds] = useState(null);
    const [attendedManAdults, setattendedManAdults] = useState(null);
    const [attendedWomanAdults, setattendedWomanAdults] = useState(null);
    const [IsRegistrationOpen, setIsRegistrationOpen] = useState(true);
    const [street_address, setstreet_address] = useState(null)
    const [areas, setareas] = useState([]);
    const [errors, seterrors] = useState({});
    const [IsRegister, setIsRegister] = useState(false);
    const [isNumberRegister, setisNumberRegister] = useState(false);

    const [firstName, setfirstName] = useState(null)
    const [lastName, setlastName] = useState(null)
    const [enableInput, setenableInput] = useState(true)
    const [remark, setremark] = useState(null)
    const [area, setarea] = useState(null)
    const [addressComponent, setaddressComponent] = useState(null)
    const [Pincode, setPincode] = useState(null)
    const [QRCodeValue, setQRCodeValue] = useState("")
    const [ccode, setccode] = useState("+91")
    const [divImage, setdivImage] = useState(VUF_TEMP)

    useEffect(() => {

        // console.log(props)
        // const { id } = props.params;

        setEventID(id);
        setbannerShow(Show);


        function getEvent() {
            let urlPath = "event/eventdetails/" + id

            fetch(url.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.json())
                .then((responseData) => {

                    if (responseData.status) {
                        var datares = responseData.data

                        setEvent(datares)
                        setlocationDetails(responseData.location)

                        // if ( datares.eventBannerImage != null && datares.eventBannerImage != "") {
                        //     setdivImage("https://api.app.vishvumiyafoundation.org/" + datares.eventBannerImage);
                        //     console.log(datares.eventBannerImage)
                        // }
                        console.log(divImage)

                        var current = new Date();
                        var RegstartD = new Date(datares.registrationStartDate);
                        var RegendD = new Date(datares.registrationEndDate);
                        if (RegstartD <= current && RegendD >= current) {
                            setIsRegistrationOpen(true)

                            if (datares.MaxRegistration > 0) {
                                if (datares.MaxRegistration >= responseData.UserCount) {
                                    setIsRegistrationOpen(false)
                                }
                            }

                        } else {
                            setIsRegistrationOpen(false)
                        }

                        if (datares.RegistrationOpen == "true") {
                            setIsRegistrationOpen(true)
                        }
                        // eventName= datares.eventName
                        // eventStartDate= new Date(datares.eventStartDate)
                        // eventEndDate= new Date(datares.eventEndDate)
                        // registrationStartDate= new Date(datares.registrationStartDate)
                        // registrationEndDate= new Date(datares.registrationEndDate)

                    } else {
                        alert(
                            'Something went wrong, Please try again after sometime.'
                        )
                        // this.setState({ loading: false });
                    }
                }).catch(error => {
                    console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }).finally(final => {
                    // return this.setState({ isInvitingUser: false })
                });

        }
        getEvent()

    }, []);


    const handleMobileNoChange = (event) => {
        const newMobileNo = event.target.value;

        // Regular expression for exactly 10 numeric characters
        const numericRegex = /^[0-9]{0,10}$/;

        // Validate the input against the regular expression
        const isValid = numericRegex.test(newMobileNo);

        // Update the isValidMobileNo state based on the validation result
        setIsValidMobileNo(isValid);

        // If the input is valid or empty, and the length is less than or equal to 10, update the mobileNo state
        if ((isValid || newMobileNo === '') && newMobileNo.length <= 10) {
            setMobileNo(newMobileNo);
        }
    };
    function handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (firstName?.trim() === "") {
            formIsValid = false;
            errors.firstName = "Please enter first name";
        }

        if (lastName?.trim() === "") {
            formIsValid = false;
            errors.lastName = "Please enter last name";
        }
        const regexPin = /^\d{0,5}$/;
        if (Pincode?.trim() === "") {

            formIsValid = false;
            errors.Pincode = "Please enter Pincode";

        } else if (regexPin.test(Pincode)) {
            formIsValid = false;
            errors.Pincode = "Please enter 6 digit Pincode";
        }
        if (street_address?.trim() === "") {
            formIsValid = false;
            errors.Pincode = "Please enter correct Pincode";
        }



        // if (this.state.ccode?.trim() === "") {
        //     formIsValid = false;
        //     errors.ccode = "Please enter country code";
        // }

        // if (this.state.email?.trim() === "") {
        //     formIsValid = false;
        //     errors.email = "Please enter email address";
        // }

        if (mobileNo?.trim() === "") {
            formIsValid = false;
            errors.mobileNo = "Please enter mobile number";
        }

        console.log(errors);


        seterrors(errors);

        return formIsValid;
    }
    function reset() {
        setQRCodeValue(""); setMobileNo(""); seterrors({}); setfirstName(""); setlastName(""); setarea(""); setareas([]);
        setPincode(""); setstreet_address(""); setaddressComponent(null);

    }

    async function onGetCountryList() {
        let urlPath = "auth/CountryList"

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;

                    let countriesdata = []

                    data.map((c, i) => {
                        countriesdata.push({ label: c.country_name, id: c._id, Code: c.id })
                    })

                    setcountries(countriesdata);

                    if (selectedCountry != '' && selectedCountry != null) {
                        let stateCode = countries.filter(s => s.label == selectedCountry)[0].Code
                        onGetStateList(stateCode);
                    } else {
                        selectedCity = ""
                        selectedDistrict = ""
                        selectedState = ""
                    }
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {

            });
    }
    function onGetStateList(data) {
        if (data != null && data != '') {

            let urlPath = "auth/StatesList/" + data

            fetch(url.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: Body,
            }).then((response) => response.json())
                .then(async (responseData) => {
                    // // // console.log("RES ",responseData)
                    if (responseData.status) {
                        var resp = responseData.response;

                        let stateListdata = []

                        resp.map((c, i) => {
                            stateListdata.push({ label: c.state_title, id: c._id, Code: c.state_id })
                        })
                        setstateList(stateListdata)

                        if (selectedState != '' && selectedState != null) {
                            let stateCode = stateList.filter(s => s.label == selectedState)[0].Code
                            onGetDistrictList(stateCode);
                        } else {
                            selectedState = ""
                            selectedDistrict = ""
                            selectedCity = ""

                        }

                    }
                }).catch(error => {
                    // // console.log("err", error);

                }).finally(final => {
                    //return this.setState({ loading: false })
                });
        }
    }
    function onGetDistrictList(data) {

        let urlPath = "auth/DistrictsList/" + data

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var datares = responseData.response;
                    let districtListdata = []

                    datares.map((c, i) => {
                        districtListdata.push({ label: c.district_title, id: c._id, Code: c.districtid })
                    })

                    setdistrictList(districtListdata)

                    if (selectedDistrict != '' && selectedDistrict != null) {
                        let districtCode = districtList.filter(s => s.label == selectedDistrict)[0].Code
                        onGetCitiesList(districtCode);
                    } else {
                        selectedDistrict = ""
                        selectedCity = ""
                    }


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                // return this.setState({ loading: false })
            });
    }
    function onGetCitiesList(data) {
        let urlPath = "auth/CitiesList/" + data

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var datares = responseData.response;
                    let CityListdata = []

                    datares.map((c, i) => {
                        CityListdata.push({ label: c.name, id: c._id, Code: c.id })
                    })

                    setCityList(CityListdata);


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                //return this.setState({ loading: false })
            });
    }
    function onChangePostcode(e) {
        console.log(e)
        //let urlPath = "https://api.postalpincode.in/pincode/" + e;

        if (e != null && e != "") {
            let urlPath = "auth/PostalCode/" + e;

            fetch(url.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);

                    if (responseData.status) {

                        if (responseData.response[0].PostOffice != null && responseData.response[0].PostOffice.length > 0) {
                            var data = responseData.response[0].PostOffice[0];
                            setselectedCountry(data.Country)
                            setselectedCity(data.Block)
                            setselectedDistrict(data.District)
                            setselectedState(data.State)
                            setstreet_address(data.Country + ", " + data.State + ", " + data.District + ", " + data.Block)
                            var areadata = area;
                            let areas = []
                            responseData.response[0].PostOffice.map((c, i) => {
                                areas.push({ label: c.Name, id: c.Name, Code: c.Name })
                                if (areadata == c.Name) {
                                    setarea({ label: c.Name, id: c.Name, Code: c.Name })
                                }
                            })
                            console.log(data)
                            setareas(areas)
                            //this.onGetCountryList();
                        }
                    }



                }).catch(error => {
                    console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                });
        } else {

        }
    }
    function checkMobileNumberIsRegistrered(event) {
        const inputValue = event.target.value;
        const regex = /^\d{0,10}$/; // Regex to match exactly 10 digits
        setMobileNo(inputValue)
        if (regex.test(inputValue) && inputValue.length == 10) {
            numberCheckAPI()
        } else {
            seterrors({ isNumberVerified: false })
        }
    };
    function numberCheckAPI() {

        // this.setState({ loading : true })
        //this.setState({ info: { type: "info", msg: "Looking for user, wait..." }, errors: {}, isNumberVerified: true, loading: false });
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })

        const Body = JSON.stringify({
            'countryCode': ccode,
            eventId: eventId,
            "mobileNo": mobileNo.toString().trim(),
            'client': url.AUTH_APP_NAME,
            'client_secret': url.AUTH_SECRET,
            'eventId': eventId,

        })

        let urlPath = "auth/check-mobile-eventregistered";
        //let urlPath = "check-mobile-registered";

        // console.log(Constants.AUTH_URL + urlPath, token, Body)
        // this.setState({ loading : false })
        // return

        fetch(url.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: Body,
        }).then((response) => response.json())
            .then((responseData) => {
                setIsRegister(false);
                setenableInput(true);
                //this.state.firstName = "";
                //this.state.lastName = "";
                //this.state.userId = "";
                console.log(responseData);
                let errors = {}
                setisNumberRegister(true);
                if (responseData.response != null) {

                    if (responseData.response.user != null) {
                        errors.mobileNo = "User already registered.";
                        var user = responseData.response.user
                        setfirstName(user.firstName);
                        setlastName(user.lastName);
                        setUserID(user._id);
                        setenableInput(false);
                        setremark(user.remarks);
                        setselectedCity(user.city);
                        setselectedCountry(user.country);
                        setselectedDistrict(user.district);
                        setselectedState(user.state);
                        setarea(user.area);
                        setaddressComponent(user.street_address);
                        setPincode(user.Pincode);
                        if (user.Pincode != "") {
                            onChangePostcode(user.Pincode);
                        }

                    }
                    if (responseData.response.event != null) {
                        errors.mobileNo = "User already registered in event.";
                        var event = responseData.response.event
                        // this.state.eventId = event._id;
                        if (event != null) {
                            setQRCodeValue(userId +
                                "_" + event._id)
                        } else {
                            setQRCodeValue("")
                        }

                        setIsRegister(true);
                        // this.state.BoyChilds = event.BoyChilds;
                        // this.state.GirlChilds = event.GirlChilds;
                        // this.state.ManAdults = event.ManAdults;
                        // this.state.WomanAdults = event.WomanAdults;
                    }
                    seterrors(errors);
                    // this.setState({ errors: errors, info: { type: "info", msg: "" }, isNumberVerified: false, loading: false });
                }
                else

                    if (responseData.status && responseData.auth) {

                        errors.mobileNo = responseData.error
                        seterrors(errors);
                        //this.setState({ errors: errors, info: { type: "info", msg: "" }, isNumberVerified: false, loading: false });
                    }
                    else if (!responseData.status && responseData.error) {
                        //errors.mobileNo = responseData.error
                        if (responseData.error == "User not found.") {
                            setisNumberRegister(false);
                        }
                        seterrors(errors);
                        // this.setState({ errors: errors, info: { type: "info", msg: "" }, isNumberVerified: false, loading: false });
                        // this.setState({ loading: false });
                    }
                    else if (responseData.error && typeof responseData.error != "object") {
                        seterrors({});
                        // this.setState({ info: { type: "success", msg: "User is valid" }, errors: {}, isNumberVerified: true, loading: false });


                    } else if (typeof responseData.error === "object") {

                        alert(
                            'Error!  \n\n ' + responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
                        )
                        // this.setState({ loading : false })
                        let errors = {}

                        errors.mobileNo = "Something went wrong.";
                        seterrors(errors);
                        //this.setState({ errors: errors, isNumberVerified: false, loading: false });
                    } else {

                        alert(
                            'Oops! \n\n Something went wrong, Please try again after sometime.'
                        )

                        let errors = {}

                        errors.mobileNo = "Something went wrong.";
                        seterrors(errors);
                        //this.setState({ errors: errors, isNumberVerified: false, loading: false });
                    }

            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                let errors = {}

                errors.mobileNo = "Something went wrong.";
                seterrors(errors);

            }).finally(final => {

            });
    }
    function onUserInvite(e) {
        console.log(handleValidation())
        if (!handleValidation()) {
            // // console.log("FAILED")

            return false
        }
        e.preventDefault()
        if (isNumberRegister == false) {
            const Body = JSON.stringify({
                // sangathanId: selectedSangathan._id,
                "firstName": firstName,
                "lastName": lastName,
                "mobileNo": mobileNo.trim(),
                "remarks": remark || '',
                //"location": selectedLocation,
                "street_address": street_address,
                "addressComponent": addressComponent,
                'countryCode': ccode,
                'client': url.AUTH_APP_NAME,
                'client_secret': url.AUTH_SECRET,
                'city': selectedCity || '',
                'district': selectedDistrict || '',
                'state': selectedState || '',
                'Pincode': Pincode || '',
                'country': selectedCountry || '',
                //'cityCode': cityCode || '',
                'area': area.label || '',
                // 'districtCode': districtCode || '',
                // 'stateCode': stateCode || '',
                // 'countryDataCode': countryDataCode || '',
                // '_id':userId||'',

            });
            let urlPath = "eventUser";

            fetch(url.AUTH_URL + urlPath, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer SECRET'
                },
                body: Body,
            }).then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);
                    if (responseData.status && responseData.response) {

                        setUserID(responseData.response.user._id)

                        setTimeout(() => {
                            UserEventCreate(e);
                        }, 100)

                    } else if (!responseData.status && responseData.error) {
                        alert(
                            responseData?.error || "Error")
                        // this.setState({ loading: false });
                    } else {
                        alert(
                            'Something went wrong, Please try again after sometime.'
                        )
                        //this.setState({ loading: false });
                    }
                }).catch(error => {
                    console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    //this.setState({ loading: false });
                }).finally(final => {
                    // return this.setState({ isInvitingUser: false })
                });
        } else {

            const Body = JSON.stringify({
                // sangathanId: selectedSangathan._id,
                "firstName": firstName,
                "lastName": lastName,
                "remarks": remark || '',
                "street_address": addressComponent,
                'countryCode': ccode,
                'client': url.AUTH_APP_NAME,
                'client_secret': url.AUTH_SECRET,
                'city': selectedCity || '',
                'district': selectedDistrict || '',
                'state': selectedState || '',
                'Pincode': Pincode || '',
                'country': selectedCountry || '',
                'area': area.label || ''

            });
            let urlPath = "userPincodeupdate/" + userId;

            fetch(url.AUTH_URL + urlPath, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer SECRET'
                },
                body: Body,
            }).then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);
                    if (responseData.status) {
                        setTimeout(() => {
                            UserEventCreate(e);
                        }, 100)

                    } else if (!responseData.status && responseData.error) {
                        alert(
                            responseData?.error || "Error")
                        //this.setState({ loading: false });
                    } else {
                        alert(
                            'Something went wrong, Please try again after sometime.'
                        )
                        //this.setState({ loading: false });
                    }
                }).catch(error => {
                    console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    //this.setState({ loading: false });
                }).finally(final => {
                    // return this.setState({ isInvitingUser: false })
                });
        }
    }

    function UserEventCreate(e) {
        const Body = JSON.stringify({
            // sangathanId: selectedSangathan._id,
            // BoyChilds: BoyChilds,
            // GirlChilds: GirlChilds,
            // ManAdults: ManAdults,
            // WomanAdults: WomanAdults,
            // selectedLocation: selectedLocation,
            // role: assignRole,
            userId: userId,
            eventId: eventId,
            "firstName": firstName,
            "lastName": lastName,
            "mobileNo": mobileNo.trim(),
            "Pincode": Pincode,
            // "remarks": remark || '',
            "addressComponent": addressComponent || '',
            //"location": selectedLocation,
            'countryCode': ccode,
            'client': url.AUTH_APP_NAME,
            'client_secret': url.AUTH_SECRET,
            'city': selectedCity || '',
            'district': selectedDistrict || '',
            'state': selectedState || '',
            'country': selectedCountry || '',
            //'cityCode': cityCode || '',
            //'districtCode': districtCode || '',
            //'stateCode': stateCode || '',
            'area': area.label || '',
            //'countryDataCode': countryDataCode || '',

        });
        let urlPath = "event/registerEvent";

        fetch(url.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer SECRET'
            },
            body: Body,
        }).then((response) => response.json())
            .then((responseData) => {
                console.log(responseData);
                if (responseData.status && responseData.response) {
                    alert(
                        "Event Register Successfully.")
                    setQRCodeValue(responseData.response.userId +
                        "_" + responseData.response.eventId)


                } else if (!responseData.status && "auth" in responseData) {
                    //AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")
                    // this.setState({ loading: false });
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    //this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                //this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });
    }

    // console.log(this.state.loading)
    const formatDate = (stdate) => {
        if (stdate != null) {
            const date = new Date(stdate); // 8th September 2024 (Month is 0-based, so 8 represents September)
            const options = { day: "numeric", month: "long" };
            return date.toLocaleDateString("en-GB", options); // "8 September"
        }
    };
    //   loading
    //   ?
    //   <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
    //   :


    return (
        <>
            <div  >

                {/* Header with Logo and Event Title */}
                <header className="header">
                    <img src={logoVSP} alt="Vishv Umiya Logo" className="logo" />
                    <h1>Event Registration</h1>
                </header>

                {/* Banner with Background Image and Event Details */}
                {event != null &&
                    <div>
                        {Show=="true" &&
                            <div className="banner" style={{ backgroundImage: 'url(' + divImage + ')' }}>
                                {event != null &&
                                    <div className=''>
                                        <h2>{event.eventName}</h2>
                                        <p>{formatDate(event.eventStartDate)}

                                        </p>
                                        <p>Venue: {event != null && event.Address != null ? <span> {event.Address} </span> : <span> Country - {event.country}. State - {event.state}. City - {event.city} </span>}
                                            {/* {this.state.locationDetails != null && <span >{this.state.locationDetails.name}</span>}  */}
                                        </p>
                                    </div>
                                }


                            </div>
                        }
                        {(Show =="false" && (event.eventBannerImage != null && event.eventBannerImage != "")) &&
                            <div className="bannerimg" style={{ backgroundImage: 'url(' + divImage + ')' }}>
                            </div>
                        }
                    </div>
                }

                {/* <div  style={{ backgroundImage: `url("../assets/images/vuf-border.png") `,backgroundRepeat:'repeat' }}>
</div> */}
                <div class="temple-border"></div>
                {/* <img src="../assets/images/vuf-border.png" style={{backgroundRepeat:'repeat-x',backgroundSize:'auto 40px'}} alt="border Image" class="temple-border" /> */}
                {/* Registration Form */}
                <div className="container">
                    {IsRegistrationOpen == true ?
                        <div className="card-body row" style={{ paddingTop: 10 }}>




                            <div className='col-md-12 border-right-desktop-deprecated'>

                                {/* <div className='col-md-12' style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <label style={{ textAlign: 'center', width: '100%', fontSize: 23, fontWeight: '700', color: '#bc2f30', marginBottom: 10 }}>VUF Event Registration</label>
                                        </div> */}




                            </div>

                            {loading ?
                                <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>
                                :
                                <>

                                    <div className='col-md-12'>

                                        <div className='row'>
                                            <div className='col-12 col-lg-12'>

                                                <label>Mobile Number / મોબાઇલ નંબર:</label>
                                                <div className='row'>
                                                    <div className='col-3  col-md-2'>


                                                        <input type="text" name="ccode" disabled="true" className="form-control p-1 form-control-solid" placeholder="+91" value={ccode} />
                                                        <span className="form-text text-danger">{errors?.ccode}</span>
                                                    </div>
                                                    <div className='col-9 col-md-10'>
                                                        <input type="text" name="mobileNo" disabled={QRCodeValue != ""} className="form-control form-control-solid" placeholder="Mobile number" onChange={handleMobileNoChange} onBlur={(event) => checkMobileNumberIsRegistrered(event)} value={mobileNo} />
                                                        <span className="form-text text-danger">{errors?.mobileNo}</span>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        {QRCodeValue == "" &&
                                            <div className='row'>
                                                <div className='col-12 col-lg-6'>

                                                    <label>First Name / નામ:</label>
                                                    <input type="text" name="firstName" disabled={!enableInput} className="form-control form-control-solid" onChange={(event) => setfirstName(event.target.value)} placeholder="Enter first name" value={firstName} />
                                                    <span className="form-text text-danger">{errors?.firstName}</span>
                                                </div>

                                                <div className="col-12 col-lg-6">

                                                    <label>Last Name / અટક:</label>
                                                    <input type="text" name="lastName" disabled={!enableInput} className="form-control form-control-solid" onChange={(event) => setlastName(event.target.value)} placeholder="Enter last name" value={lastName} />
                                                    <span className="form-text text-danger">{errors?.lastName}</span>
                                                </div>

                                            </div>
                                        }  {QRCodeValue == "" &&
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label>Pincode / પિનકોડ: </label>
                                                    <input type="text" name="Pincode"
                                                        className="form-control form-control-solid"
                                                        onBlur={(event) => { onChangePostcode(event.target.value) }}
                                                        onChange={(event) => setPincode(event.target.value)}
                                                        placeholder="Pincode" value={Pincode} />
                                                    <span className="form-text text-danger">{errors?.Pincode}</span>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Country / દેશ, State / રાજ્ય, District / જિલ્લો, City / શહેર </label>

                                                    <input type="text" name="street_address" disabled="true" className="form-control form-control-solid" value={street_address} />

                                                </div>
                                                <div className="col-md-2">
                                                    <label>Area / વિસ્તાર (Optional): </label>

                                                    <Select disabled={!enableInput}
                                                        onChange={(event) => setarea(event)}
                                                        classNamePrefix="select2-selection"
                                                        value={area}
                                                        options={areas}
                                                    />


                                                </div>

                                            </div>
                                        }  {QRCodeValue == "" &&
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label>Address / સરનામું (Optional):</label>
                                                    <textarea disabled={!enableInput} type="text" name="addressComponent" className="form-control form-control-solid" placeholder="Enter Address" >
                                                        {addressComponent}
                                                    </textarea>
                                                    <span className="form-text text-danger">{errors?.addressComponent}</span>
                                                </div>

                                            </div>
                                        }
                                        {QRCodeValue != "" &&
                                            <div className='text-center  mt-2'>
                                                <span className='h5'>Thank you for Registration!!</span> <br />
                                                <h1>Scan Registration OR</h1>
                                                <QRCode
                                                    // size={256}
                                                    //style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={QRCodeValue}
                                                // viewBox={`0 0 256 256`}
                                                />
                                                <br />
                                                <br />
                                                <button type="button" className="btn btn-primary mr-2" onClick={(event) => reset()} >Reset</button>
                                            </div>
                                        }
                                        {IsRegistrationOpen == true && QRCodeValue == "" ?
                                            <div className='col-md-12 text-center' style={{ paddingTop: 20, marginBottom: 0 }}>
                                                <div className='col-md-21 col-sm-12 col-sm-12 margin-top-mob' >
                                                    <div className=" text-center">
                                                        <button type="button" className="btn btn-primary mr-2" onClick={(event) => onUserInvite(event)} >Register Now</button>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                        }
                                    </div>

                                </>

                            }



                        </div>
                        :
                        <div className="card-body row">

                            <label>Registration Close</label>

                        </div>
                    }

                </div>

                {/* Footer with Red Background and Footer Image */}
                {/* <div className='temple-image'>
    <img src="../assets/images/temple.jpg" alt="Temple Image" class="" />
</div> */}
                <div style={{ marginLeft: "80%" }}>
                    <img src={TEMP} alt="Temple Image" class="temple-image" />
                </div>
                <div class="reg-footer">

                    <p class="iso">ISO 9001:2015 Approval Number: 00044220</p>
                    <p class="copyright">Copyright © 2024 Vishv Umiya Foundation. All Rights Reserved.</p>
                </div>


            </div>
        </>
    );
};

export default connect(null, { setBreadcrumbItems })(RegisterEvent);
