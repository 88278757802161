import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { connect } from "react-redux";
import Select from "react-select"
import { setBreadcrumbItems } from "../../store/actions";
import { useParams } from "react-router-dom";
import Spinners from "components/Common/Spinner"
import * as url from "../../helpers/url_helper"
import "flatpickr/dist/themes/material_blue.css"
import { Link } from 'react-router-dom';
import moment from 'moment';


const UsersDetails = (props) => {
    document.title = "User Details - VUF";
    const { Type, id } = useParams();
    const [locationdata, setlocation] = useState([]);
    const [state, setstate] = useState({});
    const [isLoading, setLoading] = useState(false)

    const breadcrumbItems = [
        { title: "VUF", link: "#" },
        { title: "UserDetails", link: "#" }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('List', breadcrumbItems)
    })
    useEffect(() => {
        onGetLocationsList()
    }, []);

    function onUserInvite(e) {
        setLoading(true)
        let urlPath = "GetUserDetails/" + id;
        fetch(url.AUTH_URL + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then((responseData) => {

                var typedata = Type.split(/_(.*)/s)
                var urlredirect = typedata[0];
                if(typedata.length>1){
                    urlredirect=urlredirect+  "/" + typedata[1];
                }
                
                console.log(urlredirect)
                if (responseData.status == true) {
                    var user = responseData.response.user
                    console.log(user);

                    var data = {
                        country: user.country,
                        address1: user.address1 || '--',
                        firstName: user.firstName,
                        lastName: user.lastName,
                        mobileNo: user.mobileNo,
                        description: user.description || '--',
                        location: user.selectedLocation,
                        countryCode: user.countryCode || '--',
                        dob: user.birthDate != '' && user.birthDate != undefined ? moment(user.birthDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '--',
                        age: user.age || '--',
                        gender:user.gender || '--',

                        nickname: user.NickName || '--',
                        profession: user.Profession || '--',
                        oterProfession: user.OtherProfession || '--',
                        area: user.area || '--',
                        pincode: user.postal_code || '--',
                        street_address: user.street_address,
                        location: locationdata.length>0? locationdata.filter(s => s.place_id == user.location)[0].name:"--",
                        city: user.city || '--',
                        district: user.district || '--',
                        state: user.state || '--',
                        country: user.country || '--',
                        village: user.village || '--',
                        Type: urlredirect || '--'

                    };

                    setstate(data);

                    console.log("done");
                    //this.onGetLocationsList();
                } else {
                    alert(
                        'Oops! \n\n Something went wrong, Please try again after sometime.'
                    )

                }

            }).catch(error => {
                // // console.log("err", error);
                // alert(
                //     'Something went wrong, Please try again after sometime.'
                // )

            }).finally(final => {
                // this.recaptchaRef.current.reset();
                // return this.setState({ isInvitingUser: false })
            });
            setLoading(false)
    }

    function onGetLocationsList() {
        let urlPath = "DynamicPage/entry-locations/"
        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status && 'data' in responseData) {
                    const { data } = responseData;
                    const LocationsList = data.filter(item => item.status === "true").map((item) => {
                        return { ...item, Name: item.name, id: item.place_id, Value: item.name }
                    });
                    setlocation(LocationsList)
                    onUserInvite()
                }
            }).catch(error => {
            }).finally(final => {
            });
    }


    return (

        <React.Fragment>

            {isLoading ? <Spinners /> :
                <>
                    <div className="">
                        <div className="container">
                            <div className="row">
                                <div className="col-10"></div>
                                <div className="col-2">
                                    <Link to={'/' + state.Type} className="btn btn-danger btn-sm h5 m-3  mr-2  " >
                                        <span className="menu-text" style={{ color: 'white' }}>Back to List</span>
                                    </Link>
                                </div>

                                <br />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">


                                    <div className="card-body row" style={{ paddingTop: 10 }}>


                                        <div className='row m-3'>
                                            <div className="col-md-3">
                                                <label>Mobile Number / મોબાઇલ નંબર:</label><br />
                                                <b>{state.countryCode} {state.mobileNo}</b>

                                            </div>
                                            <div className='col-md-3'>
                                                <div className="">
                                                    <label>First Name / નામ:</label><br />
                                                    <b>{state.firstName} </b>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="">
                                                    <label>Last Name / અટક: </label><br />
                                                    <b>{state.lastName} </b>
                                                </div>
                                            </div>
                                            <div className='col-md-2'>
                                                <label>Location:</label> <br />
                                                <b>{state.location}</b>
                                            </div>
                                            {/* <div className='col-md-4'>
                                                <div className="">
                                                    <label>Nick Name / ઉપનામ (Optional):</label><br />
                                                    <b> {state.NickName} </b>

                                                </div>
                                            </div> */}
                                        </div>

                                        <div className='row m-3'>

                                            <div className='col-md-3'>

                                                <div className="" >
                                                    <label>Profession / વ્યવસાય (Optional):</label><br />
                                                    <b>{state.profession}</b> {state.Profession == "Others" && <b>, {state.OtherProfession}</b>}
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="">
                                                    <label>Pincode / પિનકોડ</label><br />
                                                    <b>{state.Pincode}</b>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="">
                                                    <label>Country / દેશ, State / રાજ્ય, District / જિલ્લો, City / શહેર, Village / ગામ, Area / વિસ્તાર</label><br />
                                                    <b>{state.country}</b>  <b>, {state.state}</b><b>, {state.district}</b> <b>, {state.city}</b><b>, {state.village}</b><b>, {state.area}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row m-3'>
                                            <div className="col-md-6">
                                                <div className="">
                                                    <label>Address / સરનામું</label><br />
                                                    <b>{state.address1}</b>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="">
                                                    <label>Remarks / નૉૅધ (Optional):</label><br />
                                                    <b>{state.description}</b>

                                                </div>
                                            </div>

                                        </div>
                                        <div className='row m-3'>
                                        <div className='col-md-3'>
                                                <div className="">
                                                <label>Gender / લિંગ:</label><br/>
                                                    <b>{state.gender}</b>
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className="">
                                                    <label>Birthdate / જન્મતારીખ (Optional):</label><br />
                                                    <b>{state.dob}</b>
                                                </div>
                                            </div>
                                            {/* <div className='col-md-2 text-center'>
                                                <div className=" mt-8">
                                                    <b>-OR-</b>
                                                </div>

                                            </div>
                                            <div className='col-md-4'>
                                                <div className="">
                                                    <label>Age(Years) / ઉંમર(વર્ષ) (Optional):</label><br />
                                                    <b>{state.age}</b>

                                                </div>
                                            </div> */}
                                        </div>
                                       
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </>

            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(UsersDetails);