import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Html5QrcodeScanner } from 'html5-qrcode';
import Select from "react-select"
import { Dots } from 'react-activity';
import * as url from "../../helpers/url_helper"
import moment from 'moment';
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import logoVSP from "../../assets/images/VUF-Logo-curve.svg";
import VUF_TEMP from "../../assets/images/VUF_temple.jpg";
import TEMP from "../../assets/images/temple.jpg";
import QRCode from "react-qr-code";
import Spinners from "components/Common/Spinner"

const RegisterVisitor = (props) => {
    const { location } = useParams();
    const [selectedLocation, setselectedLocation] = useState("");
    const [Locations, setLocations] = useState([]);

    const [result, setResult] = useState(null);
    const [loading, setloading] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [gender, setgender] = useState("");
    const [isValidMobileNo, setIsValidMobileNo] = useState(true);
    const [eventList, setEventList] = useState([]);
    const [bannerShow, setbannerShow] = useState("")
    const [user, setuser] = useState(null);
    // const [event, setEvent] = useState("");
    const [eventId, setEventID] = useState("")
    const [userId, setUserID] = useState(null);
    const [mode, setMode] = useState(null);
    const [event, setEvent] = useState(null);
    const [locationDetails, setlocationDetails] = useState(null);
    const [countries, setcountries] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [districtList, setdistrictList] = useState([]);
    const [CityList, setcityList] = useState([]);

    const [selectedCity, setselectedCity] = useState("");
    const [selectedCountry, setselectedCountry] = useState("");
    const [selectedDistrict, setselectedDistrict] = useState("");
    const [selectedState, setselectedState] = useState("");
    const [IsRegistrationOpen, setIsRegistrationOpen] = useState(true);
    const [street_address, setstreet_address] = useState(null)
    const [areas, setareas] = useState([]);
    const [errors, seterrors] = useState({});
    const [isNumberRegister, setisNumberRegister] = useState(false);

    const [firstName, setfirstName] = useState("")
    const [lastName, setlastName] = useState("")
    const [enableInput, setenableInput] = useState(true)
    const [remark, setremark] = useState(null)
    const [area, setarea] = useState(null)
    const [addressComponent, setaddressComponent] = useState(null)
    const [Pincode, setPincode] = useState("")
    const [QRCodeValue, setQRCodeValue] = useState("")
    const [ccode, setccode] = useState("+91")
    const [description, setdescription] = useState("")
    const [birthDate, setbirthDate] = useState("")
    const [age, setage] = useState("")
    const [NickName, setNickName] = useState("")
    const [Profession, setProfession] = useState("")
    const [OtherProfession, setOtherProfession] = useState("")
    const [postal_code, setpostal_code] = useState("")
    const [cityCode, setcityCode] = useState("")
    const [districtCode, setdistrictCode] = useState("")
    const [stateCode, setstateCode] = useState("")
    const [countryDataCode, setcountryDataCode] = useState("")
    const [info, setinfo] = useState({})
    const [isNumberVerified, setisNumberVerified] = useState(false);
    const [optionalFieldAllow, setoptionalFieldAllow] = useState(true);

    let locationParam = "";
    let enableLocationChange = false;

    if (location) {
        locationParam = location;
        enableLocationChange = true;
    }

    useEffect(() => {
        // ComponentDidMount behavior here
        // appendScript('/assets/js/pages/crud/file-upload/image-input.js'); // Example of appending a script, adjust as necessary.
        onGetLocationsList();
        return () => {
            onGetLocationsList();
            // ComponentWillUnmount behavior here
            // Clean up any listeners or scripts
        };
    }, []);
    function onChangePostcode(e) {
        console.log(e)
        //let urlPath = "https://api.postalpincode.in/pincode/" + e;

        if (e != null && e != "") {
            let urlPath = "auth/PostalCode/" + e;

            fetch(url.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);

                    if (responseData.status) {

                        if (responseData.response[0].PostOffice != null && responseData.response[0].PostOffice.length > 0) {
                            var data = responseData.response[0].PostOffice[0];
                            setselectedCountry(data.Country);
                            setselectedState(data.State);
                            setselectedDistrict(data.District);
                            setselectedCity(data.Block);
                            setstreet_address(data.Country + ", " + data.State + ", " + data.District + ", " + data.Block)
                            let areas = []
                            responseData.response[0].PostOffice.map((c, i) => {
                                areas.push({ label: c.Name, id: c.Name, Code: c.Name })
                            })
                            console.log(data)
                            setareas(areas)

                            //this.onGetCountryList();
                        } else {
                            setselectedCountry(""); setselectedState(""); setselectedDistrict(""); setselectedCity(""); setarea(""); setareas([])

                            let errors = {};
                            errors.Pincode = "Please enter correct Pincode";
                            seterrors(errors);

                        }
                    } {

                    }



                }).catch(error => {
                    console.log("err", error);
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                });
        } else {
            setselectedCountry(""); setselectedState(""); setselectedDistrict(""); setselectedCity(""); setarea(""); setareas([])

        }
    }
    function onGetLocationsList() {


        let urlPath = "DynamicPage/entry-locations/"

        // // console.log("List Role =>", url.url + urlPath)

        // // // console.log("TOKEN ",token)
        // return 

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status && 'data' in responseData) {
                    const { data } = responseData;
                    let RoleList = [];

                    const LocationsList = data.filter(item => item.status === "true").map((item) => {
                        return { ...item, Name: item.name, id: item.place_id, Value: item.name }
                    })
                    // // // console.log("DL ",LocationsList)

                    if (LocationsList.length > 0) {
                        if (locationParam != "") {
                            setselectedLocation(locationParam)
                        } else {
                            setselectedLocation(LocationsList[0].place_id)
                        }
                        // setSelectedLocation(LocationsList[0])

                    }

                    setLocations(LocationsList)
                    //onGetCountryList();
                    // this.setState({ Locations : LocationsList})

                    //   await this.setState({ RoleList, loading: true })

                } else if (!responseData.status && "auth" in responseData) {
                    // AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    // // console.log("Something went wrong")
                    setloading(false)
                } else {
                    // // console.log("Something went wrong")
                    setloading(false)
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                setloading(false)
            });
    }
    function onGetCountryList() {

        let urlPath = "auth/CountryList"

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;

                    let countries = []

                    data.map((c, i) => {
                        countries.push({ label: c.country_name, id: c._id, Code: c.id })
                    })

                    console.log(data)
                    setcountries(countries)

                    if (selectedCountry != '' && selectedCountry != null) {
                        // stateCode = countries.filter(s => s.label == selectedCountry)[0].Code
                        // console.log(stateCode)
                        //this.onGetStateList(stateCode);
                    } else {

                        setselectedCity("");
                        setselectedDistrict("");
                        setselectedState("")

                    }
                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {

            });
    }
    function onGetStateList(data) {
        console.log(data)
        //countryDataCode = data
        if (data != null && data != undefined && data != '') {

            let urlPath = "auth/StatesList/" + data

            fetch(url.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: Body,
            }).then((response) => response.json())
                .then(async (responseData) => {
                    // // // console.log("RES ",responseData)
                    if (responseData.status) {
                        var data = responseData.response;

                        let stateList = []

                        data.map((c, i) => {
                            stateList.push({ label: c.state_title, id: c._id, Code: c.state_id })
                        })
                        console.log(stateList)
                        setstateList(stateList)
                        //this.setState({ stateList: stateList, refreshInput: false })

                        if (selectedState != '' && selectedState != null) {
                            // stateCode = stateList.filter(s => s.label == selectedState)[0].Code
                            // console.log(stateCode)
                            // this.onGetDistrictList(stateCode);
                        } else {
                            setselectedState("")
                            setselectedDistrict("")
                            setselectedCity("")

                        }

                    }
                }).catch(error => {
                    // // console.log("err", error);

                }).finally(final => {
                    //return this.setState({ loading: false })
                });
        }
    }
    function onGetDistrictList(data) {
        //stateCode = data

        let urlPath = "auth/DistrictsList/" + data

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let districtList = []

                    data.map((c, i) => {
                        districtList.push({ label: c.district_title, id: c._id, Code: c.districtid })
                    })

                    this.setState({ districtList: districtList, refreshInput: false })

                    if (selectedDistrict != '' && selectedDistrict != null) {
                        // districtCode = districtList.filter(s => s.label == selectedDistrict)[0].Code
                        // console.log(districtCode)
                        // this.onGetCitiesList(districtCode);
                    } else {
                        setselectedDistrict("")
                        setselectedCity("")
                    }


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    function onGetCitiesList(data) {
        //districtCode = data
        let urlPath = "auth/CitiesList/" + data

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let cityList = []
                    setselectedCity("")
                    data.map((c, i) => {
                        cityList.push({ label: c.name, id: c._id, Code: c.id })
                    })
                    setcityList(cityList)


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {
                return this.setState({ loading: false })
            });
    }
    /**
     *
     * @param null
     * @description This function validate inputs
     * @returns boolean
     */
    function handleValidation(event) {
        let errors = {};
        let formIsValid = true;

        if (firstName?.trim() === "") {
            formIsValid = false;
            errors.firstName = "Please enter first name";
        } else if (!/^[a-zA-Z ]*$/.test(firstName)) {
            formIsValid = false;
            errors.firstName = "Please enter a valid value containing only english letters";
        }

        if (lastName?.trim() === "") {
            formIsValid = false;
            errors.lastName = "Please enter last name";
        } else if (!/^[a-zA-Z ]*$/.test(lastName)) {
            formIsValid = false;
            errors.lastName = "Please enter a valid value containing only english letters";
        }

        if (selectedLocation === "") {
            formIsValid = false;
            errors.selectedLocation = "Please selected location";
        }

        if (ccode?.trim() === "") {
            formIsValid = false;
            errors.ccode = "Please enter country code";
        }
        else if (!/^[+]\d{0,5}$/.test(ccode)) {
            formIsValid = false;
            errors.ccode = "Please enter a valid value";
        }
        // if (street_address?.trim() === "") {
        //     formIsValid = false;
        //     errors.address = "Please select address";
        // }

        // if ((selectedCity == '' || selectedCity == null || selectedCity == undefined) && (selectedVillage == '' || selectedVillage == null ||selectedVillage == undefined)) {
        //     formIsValid = false;
        //     errors.selectedCity = "Please enter City or Village";
        // }

        if ((birthDate == '' || birthDate == null || birthDate == undefined)) {//(age == '' || age == null || age == undefined) && 
            formIsValid = false;
            errors.birthDate = "Please enter birthdate or age";
        }
        if (age != '') {
            if (!/^\d{1,3}$/.test(age)) {
                formIsValid = false;
                errors.age = "Please enter a valid age";
            }
        }

        const regexPin = /^\d{0,5}$/;
        if (Pincode?.trim() === "") {

            formIsValid = false;
            errors.Pincode = "Please enter Pincode";

        } else if (regexPin.test(Pincode)) {
            errors.Pincode = "Please enter 6 digit Pincode";
        }
        if (selectedCity?.trim() === "") {
            formIsValid = false;
            errors.Pincode = "Please enter correct Pincode";
        }
        // if( /^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$/.test(birthDate)){
        //     formIsValid = false;
        //     errors.birthDate = "Please enter a valid birthDate";
        // }

        // if (state?.trim() === "") {
        //     formIsValid = false;
        //     errors.selectedState = "Please select state";
        // }
        // if (city?.trim() === "") {
        //     formIsValid = false;
        //     errors.city = "Please select city";
        // }

        // console.log(area)
        // if (area?.trim() && !/^[a-zA-Z ]*$/.test(area)) {
        //     formIsValid = false;
        //     errors.area = "Please enter a valid value containing only english letters";
        // }

        if (description?.trim() && !/^[a-zA-Z ]*$/.test(description)) {
            formIsValid = false;
            errors.description = "Please enter a valid value containing only english letters";
        }

        if (mobileNo?.trim() === "") {
            formIsValid = false;
            errors.mobileNo = "Please enter mobile number";
        }

        seterrors(errors);

        return formIsValid;
    }
    function reCaptchaCheck(e) {
        if (!handleValidation()) {
            // // console.log("FAILED")
            return false
        }

        // let r = this.recaptchaRef.current.execute();
    }
    function onUserInvite(e) {
        // e.preventDefault()

        // this.recaptchaRef.current.executeAsync().then(value => {
        //     // // console.log("executeAsync promise - Captcha value:", value);
        // });
        if (!handleValidation()) {
            // // console.log("FAILED")
            return false
        }

        setloading(true)
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })

        const Body = JSON.stringify({
            "firstName": firstName,
            "lastName": lastName,
            "mobileNo": mobileNo.trim(),
            "remarks": description.trim(),
            "location": selectedLocation,
            'countryCode': ccode,
            'Pincode': Pincode,
            'dob': birthDate != '' ? moment(birthDate).format("YYYY-MM-DD") + "T00:00:00.000Z" : '',
            //'InterestIds': selectedUserInterest,
            'age': age || '',
            'nickname': NickName || '',

            'profession': Profession || '',
            'otherProfession': OtherProfession || '',
            'area': area.label || '',
            'pincode': postal_code || '',
            'addressComponent': addressComponent || {},
            'street_address': `${street_address}`,
            'client': url.AUTH_APP_NAME,
            'client_secret': url.AUTH_SECRET,
            'joinedFrom': "VISITOR_REGISTRATION_FORM_APP",
            'city': selectedCity || '',
            'district': selectedDistrict || '',
            'state': selectedState || '',
            'country': selectedCountry || '',
            //'village': selectedVillage || '',

            'cityCode': cityCode || '',
            'districtCode': districtCode || '',
            'stateCode': stateCode || '',
            'countryDataCode': countryDataCode || '',


        })

        let urlPath = "create-visitor";

        fetch(url.AUTH_URL + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer SECRET'
            },
            body: Body,
        }).then((response) => response.json())
            .then((responseData) => {
                // // console.log(responseData);

                if (responseData.status && 'response' in responseData) {

                    setMobileNo("")
                    setfirstName("")
                    setNickName("")
                    setage("")
                    setlastName("")
                    setdescription("")
                    setloading(false)
                    setbirthDate("")

                    setinfo({ type: "info", msg: "" });

                    // setTimeout(() => {
                    //     this.setState({ refreshInput: false })
                    // }, 100)

                    alert(
                        'Registered!\n\nVisitor entry registered successfully.',
                    )


                } else if (responseData.error && responseData.error && typeof responseData.error != "object") {
                    alert(
                        'Sorry! \n\n ' + responseData.error)
                    setloading(false)
                } else if (typeof responseData.error === "object") {
                    alert(
                        'Error!  \n\n ' + responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
                    )
                    setloading(false)
                } else {
                    alert(
                        'Oops! \n\n Something went wrong, Please try again after sometime.'
                    )
                    setloading(false)
                }

            }).catch(error => {
                // // console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                setloading(false)
            }).finally(final => {
                // this.recaptchaRef.current.reset();
                // return this.setState({ isInvitingUser: false })
            });
    }


    // function redirect() {
    //     if (isRedirect) {
    //         return <Navigate to={'/'} />
    //     }
    // }
    function formatDate(date) {
        if (!date) return "";
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
    };
    function onCaptchaChange(value) {

        // // console.log("onChange prop - Captcha value:", value);
        if (value === null || value === undefined || value === "") {
            // Handle reCAPTCHA verification failure here
            // // console.log("reCAPTCHA verification failed!");
            //   this.setState({ captchaError: true });
        } else {
            // // console.log("reCAPTCHA verification success!");
            this.onUserInvite()
            //   this.setState({ value, captchaError: false });
        }
        if (value === null) {
            alert(
                'Something went wrong, Please try again after sometime.',
            )
            this.recaptchaRef.current.reset();
            // // console.log("reCAPTCHA verification expired!");
            // this.setState({ expired: "true" });
        }
        // // // console.log("R ",r)
    }


    function numberCheckAPI() {

        // this.setState({ loading : true })
        setinfo({ type: "info", msg: "Looking for user, wait..." })
        seterrors({})
        setloading(true)
        // const { state, signOut } = this.context;
        // await this.setState({ isInvitingUser: true })

        const Body = JSON.stringify({
            'countryCode': ccode,
            "mobileNo": mobileNo.trim(),
            'client': url.AUTH_APP_NAME,
            'client_secret': url.AUTH_SECRET
        })

        let urlPath = "check-mobile-registered";

        // console.log(url.AUTH_URL + urlPath, token, Body)
        // this.setState({ loading : false })
        // return

        fetch(url.AUTH_URL + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: Body,
        }).then((response) => response.json())
            .then((responseData) => {
                // console.log(responseData);

                if (responseData.status && responseData.auth) {


                    let errors = {}

                    errors.mobileNo = "User already registered.";

                    seterrors(errors)
                    setinfo({ type: "info", msg: "" })
                    setloading(false)
                    setisNumberVerified(false)

                } else if (responseData.error && responseData.error && typeof responseData.error != "object") {
                    // alert(
                    //     'Sorry! \n\n '+responseData.error)
                    //     this.setState({ loading : false })

                    // let valids = {}

                    // valids.mobileNo = "User is valid";
                    seterrors({})
                    setinfo({ type: "success", msg: "User is valid" })
                    setloading(false)
                    setisNumberVerified(true)

                } else if (typeof responseData.error === "object") {
                    alert(
                        'Error!  \n\n ' + responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading : false })
                    let errors = {}

                    errors.mobileNo = "Something went wrong.";
                    seterrors(errors)
                    setloading(false)
                    setisNumberVerified(false)
                } else {
                    alert(
                        'Oops! \n\n Something went wrong, Please try again after sometime.'
                    )

                    let errors = {}
                    setisNumberVerified(false)
                    errors.mobileNo = "Something went wrong.";
                    seterrors(errors)
                    setloading(false)


                }

            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                let errors = {}

                errors.mobileNo = "Something went wrong.";
                seterrors(errors)
                setloading(false)
                setisNumberVerified(false)

            }).finally(final => {

            });
    }



    function checkMobileNumberIsRegistrered(event) {
        const inputValue = event.target.value;
        const regex = /^\d{0,10}$/; // Regex to match exactly 10 digits

        if (regex.test(inputValue) && inputValue.length == 10) {

            numberCheckAPI()

        } else {
            setisNumberVerified(false)
        }

    };
    function handleNumberChange(event) {
        const inputValue = event.target.value;
        const regex = /^\d{0,10}$/; // Regex to match exactly 10 digits

        if (regex.test(inputValue)) {
            // Valid input: 10 digits without spaces
            // Handle the input or set the state accordingly
            // For example:
            // console.log("N",inputValue,inputValue.length)
            setMobileNo(inputValue)
        } else {
            // // // console.log("E",inputValue)
            // Invalid input
            // You can show an error message or prevent further action
        }
    };


    /**
    * @description this function will get the input from google place autocomplete and extract city, state and country
    * @param {*} placeDetails
    * @param {*} AddressDetails
    */


    function onSelectLocation(e) {
        let location = e.target.value

        const foundItem = Locations.find(item => item.place_id == location);
        // console.log("LOC ",foundItem,this.getFormattedAddressComponentData(foundItem?.addressComponent || null) )
        setoptionalFieldAllow(foundItem?.optionalFieldAllow);
        setselectedLocation(location)
        // setResult(foundItem || null);
    }

    function formatDate(date) {
        if (!date);
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
    };


    var path = window.location.origin

    // // // console.log(loading)
    return (

        // <div className="col-md-12" style={{ height: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex' }}><Dots /></div>

        <>
            <React.Fragment>
                {loading ? <Spinners /> :
                    <div className="d-flex flex-column-fluid">

                        <div className="container" style={{ marginTop: 30 }}>
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* {
                                        ResErrors.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {ResErrors.error}
                                        </div>
                                    }
                                    {
                                        successMsg &&
                                        <div className="alert alert-success" role="alert">
                                            {successMsg}
                                        </div>
                                    } */}
                                    <div className="card p-0 card-custom gutter-b example example-compact">
                                        <div className="card-header " style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div className='row p-0'>
                                                <div className='col-md-8' style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <img style={{ width: 150 }} src={logoVSP} />
                                                </div>
                                                <div className='col-md-4' style={{ display: 'flex', justifyContent: 'flex-start' }}>

                                                    <div className="form-group" style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 0 }}>
                                                        <label>Location:</label>
                                                        <select disabled={enableLocationChange} className="form-control form-select-solid" style={{ backgroundColor: '#F3F6F9', color: enableLocationChange ? '#b7b7c4' : '#3F4254', }} aria-label="Select Location" onChange={(event) => { onSelectLocation(event) }}>

                                                            {/* <option value="">Select Location</option> */}
                                                            {
                                                                Locations.map((location) => {
                                                                    return (<option selected={selectedLocation == location.place_id} value={location.place_id}>{location.name}</option>)
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body row" style={{ paddingTop: 10 }}>



                                            <div className='col-md-8 pr-2 pl-0 border-right'>

                                                <div className='col-md-12' style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <label style={{ textAlign: 'center', width: '100%', fontSize: 23, fontWeight: '700', color: '#bc2f30', marginBottom: 10 }}>VUF Registration</label>
                                                </div>



                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <label>Mobile Number / મોબાઇલ નંબર:</label>
                                                        <div className='row'>
                                                            <div className='col-md-2 col-sm-2 col-xs-2' style={{ paddingRight: 0, width: '20%' }}>
                                                                <input type="text" name="ccode" className="form-control h-auto form-control-solid" placeholder="+91" onChange={(event) => { handleValidation(event) }} value={ccode} />
                                                                <span className="form-text text-danger">{errors?.ccode}</span>
                                                            </div>
                                                            <div className='col-md-10 col-sm-10 col-xs-10' style={{ width: '80%' }}>
                                                                <input type="text" name="mobileNo" className="form-control h-auto form-control-solid" placeholder="Mobile number" onBlur={(event) => checkMobileNumberIsRegistrered(event)} onChange={(event) => handleNumberChange(event)} value={mobileNo} />
                                                                <span className="form-text text-danger">{errors?.mobileNo}</span>
                                                                <span className={`form-text text-${info.type}`}>{info?.msg}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row mt-1'>
                                                    <div className='col-md-6'>
                                                        <label>First Name / નામ:</label>
                                                        <input type="text" disabled={!isNumberVerified} onChange={(event) => { setfirstName(event.target.value) }}
                                                            name="firstName" className="form-control h-auto form-control-solid" placeholder="Enter first name" value={firstName} />
                                                        <span className="form-text text-danger">{errors?.firstName}</span>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label>Last Name / અટક: </label>
                                                        <input type="text" disabled={!isNumberVerified} onChange={(event) => { setlastName(event.target.value) }}
                                                            name="lastName" className="form-control h-auto form-control-solid" placeholder="Enter last name" value={lastName} />
                                                        <span className="form-text text-danger">{errors?.lastName}</span>
                                                    </div>
                                                    {/* <div className='col-md-4'>
                                                    <label>Nick Name / ઉપનામ (Optional):</label>
                                                    <input type="text" disabled={!isNumberVerified} name="NickName" className="form-control h-auto form-control-solid" placeholder="Enter Nick name" value={NickName} />
                                                    <span className="form-text text-danger">{errors?.NickName}</span>
                                                </div> */}
                                                </div>
                                                <div className='row mt-1'>

                                                    <div className='col-md-6'>

                                                        <label>Profession / વ્યવસાય (Optional):</label>
                                                        <select disabled={!isNumberVerified} onChange={(e) => { setProfession(e.target.value); setOtherProfession("") }} className="form-control form-select-solid" >

                                                            <option value="" selected>Select Profession</option>
                                                            <option key="1" value="State govt. Job">State govt. Job</option>
                                                            <option key="2" value="Central govt. Job">Central govt. Job</option>
                                                            <option key="3" value="Doctor">Doctor</option>
                                                            <option key="4" value="Advocate">Advocate</option>
                                                            <option key="5" value="Engineer">Engineer</option>
                                                            <option key="6" value="Business">Business</option>
                                                            <option key="7" value="Retired">Retired</option>
                                                            <option key="8" value="Teacher">Teacher</option>
                                                            <option key="9" value="Farmer">Farmer</option>
                                                            <option key="10" value="Homemaker ( housewife)">Homemaker ( housewife)</option>
                                                            <option key="11" value="Others">Others</option>
                                                        </select>
                                                    </div>
                                                    {Profession == "Others" &&
                                                        <div className='col-md-6' >
                                                            <label>Other Profession / અન્ય વ્યવસાય:</label>
                                                            <input type="text" name="OtherProfession"
                                                                onChange={(event) => { setOtherProfession(event.target.value) }}
                                                                className="form-control  h-auto form-control-solid" placeholder="Enter your profession" value={OtherProfession} />
                                                            <span className="form-text text-danger">{errors?.OtherProfession}</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='row mt-1'>
                                                    <div className="col-md-3">
                                                        <label>Pincode / પિનકોડ: </label>
                                                        <input type="text" name="Pincode" disabled={!isNumberVerified} onChange={(event) => { setPincode(event.target.value) }}
                                                            className="form-control form-control-solid" placeholder="Pincode" onBlur={(event) => { onChangePostcode(event.target.value) }} value={Pincode} />
                                                        <span className="form-text text-danger">{errors?.Pincode}</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label>Country / દેશ, State / રાજ્ય, District / જિલ્લો, City / શહેર </label>

                                                        <input type="text" name="street_address" disabled="true" className="form-control form-control-solid" value={street_address} />

                                                    </div>
                                                    <div className="col-md-3">
                                                        <label>Area / વિસ્તાર (Optional): </label>

                                                        <Select disabled={!enableInput}
                                                            onChange={(event) => setarea(event)}
                                                            classNamePrefix="select2-selection"
                                                            value={area}
                                                            options={areas}
                                                        />

                                                    </div>
                                                    {/* <div className="col-md-4">
                                                        <label>Country / દેશ:</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled={isNumberVerified && countries.length > 0 ? false : true}
                                                            id="combo-box-demo"
                                                            value={selectedCountry}
                                                            options={countries}
                                                            onChange={(e, v, r, d) => {
                                                                // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                if (r == 'selectOption') {
                                                                    this.setState({ selectedCountry: v.label })
                                                                    this.onGetStateList(v.Code)
                                                                    //this.onSelectCountry(v.label)
                                                                } else if (r == 'clear') {
                                                                    this.setState({ selectedState: "" })
                                                                    this.setState({ selectedDistrict: "" })
                                                                    this.setState({ selectedCity: "" })
                                                                    this.setState({ selectedCountry: "" })
                                                                }
                                                            }}

                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />



                                                    </div>

                                                    <div className="col-md-4">
                                                        <label>State / રાજ્ય:</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled={isNumberVerified && stateList.length > 0 ? false : true}
                                                            id="combo-box-demo"
                                                            value={selectedState}
                                                            options={stateList}
                                                            onChange={(e, v, r, d) => {
                                                                // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                if (r == 'selectOption') {
                                                                    this.setState({ selectedState: v.label })
                                                                    this.onGetDistrictList(v.Code)

                                                                } else if (r == 'clear') {
                                                                    this.setState({ selectedDistrict: "" })
                                                                    this.setState({ selectedCity: "" })
                                                                    this.setState({ selectedState: "" })
                                                                }
                                                            }}

                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />


                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>District / જિલ્લો:</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled={isNumberVerified && districtList.length > 0 ? false : true}
                                                            id="combo-box-demo"
                                                            value={selectedDistrict}
                                                            options={districtList}
                                                            onChange={(e, v, r, d) => {
                                                                // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                if (r == 'selectOption') {
                                                                    this.setState({ selectedDistrict: v.label })
                                                                    this.onGetCitiesList(v.Code)
                                                                    //this.onSelectCountry(v.label)
                                                                } else if (r == 'clear') {
                                                                    this.setState({ selectedCity: "" })
                                                                    this.setState({ selectedDistrict: "" })
                                                                }
                                                            }}

                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />



                                                    </div> */}

                                                </div>
                                                <div className='row '>
                                                    <div className='col-md-12'>
                                                        <label>Address / સરનામું (Optional):</label>
                                                        <textarea disabled={!enableInput} type="text" name="street_address" className="form-control form-control-solid" placeholder="Enter Address" >
                                                            {street_address}
                                                        </textarea>
                                                        <span className="form-text text-danger">{errors?.street_address}</span>
                                                    </div>
                                                </div>
                                                {/* <div className='row'>
                                                    <div className="col-md-4">
                                                        <label>City / શહેર:</label>
                                                        <Autocomplete
                                                            disablePortal
                                                            disabled={isNumberVerified && cityList != undefined && cityList.length > 0 ? false : true}
                                                            id="combo-box-demo"
                                                            options={cityList}
                                                            //value={selectedCity}
                                                            onChange={(e, v, r, d) => {
                                                                // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                if (r == 'selectOption') {
                                                                    this.setState({ selectedCity: v.label, cityCode: v.Code })
                                                                    // this.onGetVillageList(v.Code)
                                                                    //this.onSelectCountry(v.label)
                                                                } else if (r == 'clear') {

                                                                    this.setState({ selectedCity: "" })
                                                                }
                                                            }}

                                                            renderInput={(params) => <TextField {...params} className="form-control form-select-solid" label="" />}
                                                        />
                                                        <span className="form-text text-danger">{errors?.selectedCity}</span>


                                                    </div>
                                                    <div className='col-md-1 text-center'>
                                                        <div className=" mt-8">
                                                            <b>-OR-</b>
                                                        </div>

                                                    </div>

                                                    <div className="col-md-3" >
                                                        <label>Village / ગામ:</label>
                                                        <input type="text" disabled={!isNumberVerified} name="selectedVillage" className="form-control py-4 px-8 h-auto form-control-solid" placeholder="Enter village" onChange={(event) => Functions.onChange(event, this)} value={selectedVillage} />
                                                        <span className="form-text text-danger">{errors?.selectedVillage}</span>
                                                    </div>

                                                    {optionalFieldAllow == true &&
                                                        <div className="col-md-4" >
                                                            <label>Area / વિસ્તાર (Optional): </label>
                                                            <input type="text" disabled={!isNumberVerified} name="area" className="form-control py-4 px-8 h-auto form-control-solid" placeholder="Enter area" onChange={(event) => Functions.onChange(event, this)} value={area} />
                                                            <span className="form-text text-danger">{errors?.area}</span>
                                                        </div>
                                                    }
                                                </div> */}

                                                {/* <div className='row'>
                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label>Country / દેશ:</label>
                                                        
                                                                <Autocomplete
                                                                    disablePortal
                                                                    // disableClearable
                                                                    disabled={countries.length > 0 ? false : true}
                                                                    value={selectedCountry}
                                                                    style={{ width : '100%', padding : 0 }}
                                                                    id="combo-box-demo-country"
                                                                    options={countries}
                                                                    sx={{ width: 300 }}
                                                                    onChange={(e,v,r,d) => { 
                                                                        // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                        if(r == 'selectOption') {
                                                                            // this.setState({ selectedCity : v.label})
                                                                            this.onSelectCountry(v.label)
                                                                        } else if(r == 'clear') {
                                                                            this.setState({ selectedCountry : "" })
                                                                            this.setState({ selectedState : "" })
                                                                            this.setState({ selectedCity : "" })
                                                                        }
                                                                     }}
                                                                    // onSelect={(e) => { this.onSelectCountry(e.target.value) }}
                                                                    renderInput={(params) => <TextField {...params} className="form-control form-control-solid" label="" />}
                                                                />
                                                                
                                                            <span className="form-text text-danger">{errors?.selectedCountry}</span>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label>State / રાજ્ય:</label>
                                                        
                                                            <Autocomplete
                                                                disablePortal
                                                                // disableClearable
                                                                disabled={stateList.length > 0 ? false : true}
                                                                value={selectedState}
                                                                style={{ width : '100%', padding : 0 }}
                                                                id="combo-box-demo-state"
                                                                options={stateList}
                                                                sx={{ width: 300 }}
                                                                onChange={(e,v,r,d) => { 
                                                                    // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                    if(r == 'selectOption') {
                                                                        // this.setState({ selectedCity : v.label})
                                                                        this.onSelectState(v.label)
                                                                    } else if(r == 'clear') {
                                                                        this.setState({ selectedState : "" })
                                                                        this.setState({ selectedCity : "" })
                                                                    }
                                                                 }}
                                                                // onSelect={(e) => { this.onSelectState(e.target.value) }}
                                                                renderInput={(params) => <TextField {...params} className="form-control form-control-solid" label="" />}
                                                            />
                                                            <span className="form-text text-danger">{errors?.selectedState}</span>
                                                        </div>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <div className="form-group">
                                                            <label>City / શહેર:</label>
                                                        
                                                           
                                                            <Autocomplete
                                                                disablePortal
                                                                disabled={CityList.length > 0 ? false : true}
                                                                value={selectedCity}
                                                                style={{ width : '100%', padding : 0 }}
                                                                id="combo-box-demo-city"
                                                                options={CityList}
                                                                sx={{ width: 300 }}
                                                                onChange={(e,v,r,d) => { 
                                                                    // console.log("E ",e,"\nV",v,"\nR",r,"\nD",d)
                                                                    if(r == 'selectOption') {
                                                                        this.setState({ selectedCity : v.label})
                                                                    } else if(r == 'clear') {
                                                                        this.setState({ selectedCity : "" })
                                                                    }
                                                                 }}
                                                                renderInput={(params) => <TextField {...params} className="form-control form-control-solid" label="" />}
                                                            />
                                                            <span className="form-text text-danger">{errors?.selectedCity}</span>
                                                        </div>
                                                    </div>

                                                </div> */}

                                                <div className='row mt-1'>
                                                    <div className='col-md-6'>
                                                        <label>Gender / લિંગ:</label>
                                                        <select disabled={!isNumberVerified} onChange={(e) => { setgender(e.target.value) }} className="form-control form-select-solid" >
                                                            <option value="" selected>Select Gender</option>
                                                            <option key="1" value="Male">Male</option>
                                                            <option key="2" value="Female">Female</option>
                                                        </select>
                                                        <span className="form-text text-danger">{errors?.gender}</span>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <label>Birthdate / જન્મતારીખ (Optional):</label>

                                                        {/* <input type="date"  name="birthDate" className="form-control form-control-solid"  
                                                            placeholder="dd/mm/yyyy"  placeholderText="Enter birthDate  (Optional)"  
                                                            onChange={(event) => this.formatDate(event.target.value)} value={birthDate} /> */}
                                                        <input
                                                            className="form-control"
                                                            type="date"
                                                            placeholderText="mm / dd / yyyy"
                                                            name="birthDate" onChange={(event) => { setbirthDate(event.target.value); handleValidation(event) }}
                                                            id="birthDate" value={birthDate}
                                                        />

                                                        {/* <DatePicker className="form-control py-4 px-8 " name="birthDate"
                                                        dateFormat="dd/MM/yyyy" placeholderText="dd / mm / yyyy"
                                                        selected={birthDate}
                                                        disabled={!isNumberVerified}
                                                        onChange={(event) => { this.setState({ birthDate: event }); this.handleValidation(event) }}
                                                        // onChange={(event) => Functions.onChange(event, this)} 
                                                        value={birthDate}
                                                    /> */}

                                                        <span className="form-text text-danger">{errors?.birthDate}</span>
                                                    </div>
                                                    {/* <div className='col-md-2 text-center'>
                                                    <div className=" mt-4">
                                                        <b>-OR-</b>
                                                    </div>

                                                </div>
                                                <div className='col-md-4'>
                                                    <label>Age(Years) / ઉંમર(વર્ષ) (Optional):</label>

                                                    <input type="text" name="age" disabled={!isNumberVerified} className="form-control form-control-solid" placeholderText="Enter age  (Optional)" onChange={(event) => { setage(event.target.value); handleValidation(event) }} value={age} />
                                                    <span className="form-text text-danger">{errors?.age}</span>
                                                </div> */}
                                                </div>


                                                {/* {optionalFieldAllow == true &&
                                                    <div className='row'>
                                                        <label className="ml-4">Interest / રસ (Optional):</label><br />
                                                        <div className='row col-md-12'>

                                                            {interestData.map((data, index) => (
                                                                <div class="col-md-4 mb-2">
                                                                    <div className="checkbox-inline ">
                                                                        <label className="checkbox m-0">
                                                                            <input type="checkbox" name="interest" onChange={(e) => { this.selectInerest(e.target.value) }} value={data._id} />
                                                                            <span  ></span>{data.name}</label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <br />
                                                    </div>
                                                }<br /> */}

                                                {optionalFieldAllow == true &&
                                                    <div className='row mt-1'>
                                                        <div className='col-md-12'>
                                                            <label>Remarks / નૉૅધ (Optional):</label>
                                                            <textarea disabled={!isNumberVerified} type="text" name="description" className="form-control form-control-solid" placeholder="Enter remarks">
                                                                {description}
                                                            </textarea>
                                                            <span className="form-text text-danger">{errors?.description}</span>
                                                        </div>
                                                    </div>
                                                }

                                                {/* <div className='row'>
                                                    <div className='col-md-12'>
                                                        <div className="form-group">
                                                            <label>Remarks / નૉૅધ:</label>
                                                            <textarea disabled={!isNumberVerified} type="text" name="remarks" className="form-control form-control-solid" placeholder="Enter remarks (Optional)" onChange={(event) => Functions.onChange(event, this)}>
                                                                {remarks}
                                                            </textarea>
                                                            <span className="form-text text-danger">{errors?.remarks}</span>
                                                        </div>
                                                    </div>
                                                </div> */}



                                                <div className="mt-3" style={{ justifyContent: 'center', textAlign: 'center' }}>
                                                    {

                                                        <button type="button" disabled={!isNumberVerified} className="btn btn-primary" onClick={(event) => onUserInvite()}>Register Now</button>
                                                    }

                                                    {/* <Link to={'/ListBranch/' + OrganizationID} className="btn btn-secondary">Cancel</Link> */}
                                                </div>




                                            </div>


                                            <div className='col-md-4'>
                                                <div className="col-md-12" style={{ marginTop: 0 }}>
                                                    {!enableLocationChange && <label style={{ textAlign: 'center', width: '100%', fontSize: 22, fontWeight: '600' }}>Register QR</label>}
                                                    {!enableLocationChange &&
                                                        <div className="row " style={{ justifyContent: 'center', marginBottom: 0 }}>
                                                            <div className="col-md-8" style={{ marginTop: 10 }}>
                                                                <div className='qr-full' style={{ height: "auto", margin: "0 auto", maxWidth: '100%', width: "100%" }}>
                                                                    {/* <label style={{ textAlign : 'center', width : '100%', fontSize : 14, fontWeight : '500' }}>Register QR</label> */}
                                                                    <QRCode
                                                                        size={100}
                                                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                        value={`${path}/register/${selectedLocation}`}
                                                                        viewBox={`0 0 156 156`}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </div>


                                            </div>
                                            {/* <ReCAPTCHA
                                                ref={this.recaptchaRef}
                                                size="invisible"
                                                sitekey="6Le5UzgpAAAAADrpPAkYdXobxOMc-99mN9DJqpZ3"
                                                onChange={this.onCaptchaChange}
                                                /> */}
                                        </div>
                                        {/* <div className="card-footer hide-mob" style={{ justifyContent : 'center', textAlign :'center'}}>
                                            {
                                                btnLoading ?
                                                    <button type="button" className="btn btn-primary mr-2"><Spinner color={'#fff'} /></button>
                                                    :
                                                    <button type="button" className="btn btn-primary mr-2" onClick={(event) => this.reCaptchaCheck(event)}>Register Now</button>
                                            }
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        </>

    );

}
export default connect(null, { setBreadcrumbItems })(RegisterVisitor);