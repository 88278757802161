import * as url from "../../helpers/url_helper"

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Input, FormFeedback } from 'reactstrap';
import logoVSP from "../../assets/images/VUF-Logo-curve.svg";
import withRouter from 'components/Common/withRouter';
import { withTranslation } from "react-i18next"
import { loginUser, socialLogin } from "../../store/actions";
import Spinners from "components/Common/Spinner"

const data = {
	loading: false,
	loginBntLoader: false,
	mobile_no: '',
	isOTP: false,
	otp: '',
	country_code: '+91',
	errors: {},
	timer: 60,
	resend: true,
	verified: false,
	firebaseUser: '',
	height: 600,
	tab: 'login',
	OrganizationList: [
		{ Name: "BCP", ID: 89, label: "BCP", value: 89 }
	],
	confirmationResult: null,
	name: '',
	email: '',
	orgId: '',
	registering: false,
	captchaLoaded: false,
	loginMode: "mobile", // [ "mobile", "email" ]
	username: "",
	password: "",
	RememberMe: false,
	mailLoader: false,
	success: false,
	successMsg: ''
}
const Login = (props) => {
	const [isLoading, setLoading] = useState(false)
	const [state, setstate] = useState(data);
	const [Timer, setTimer] = useState({});
	const dispatch = useDispatch();
	useEffect(() => {
		setstate((prevState) => ({
			...prevState, height: window.screen.height - 113
		}))
		if (localStorage.RememberMe && localStorage.email !== "") {
			setstate((prevState) => ({
				...prevState,
				RememberMe: true,
				username: localStorage.username,
				password: localStorage.password
			}))
		}
	})


	/**
	 * This is validation function
	 * @param null
	 * @returns boolean
	 * @description validate country code and mobile number
	 */
	function handleValidation() {
		let errors = {};
		let formIsValid = true;

		if (!state.country_code) {
			formIsValid = false;
			errors["error"] = "Fill all inputs";
		}

		if (!state.mobile_no) {
			formIsValid = false;
			errors["error"] = "Fill all inputs";
		}

		if (state.mobile_no.length != 10) {
			formIsValid = false;
			errors["error"] = "Please enter valid mobile number";
		}

		setstate((prevState) => ({
			...prevState, errors: errors
		}));
		setLoading(false)
		return formIsValid;
	}

	/**
	 * This function send otp to given mobile number
	 * @param {*} event
	 * @returns otp
	 */
	function sendOTP(event) {
		let errors = {};

		//!this.state.captchaLoaded 

		if (handleValidation()) {
			setstate((prevState) => ({
				...prevState, loginBntLoader: true,
			}));
			const phoneNumber = state.country_code + state.mobile_no;
			const appVerifier = window.recaptchaVerifier;
		}
	}

	/**
	 * Authenticate user
	 * @param {*} event
	 * @description This function authenticate user is registered or not
	 */
	function authenticateNumber(event) {
		let errors = {};


		if (handleValidation()) {
			// console.log(JSON.stringify({ PhoneNumber: "+"+ccode+number}));
			// console.log(Constants.url + "Account/Login");
			fetch(url.url + "Account/Login",
				{
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						PhoneNumber: state.country_code + state.mobile_no,
					}),
				})
				.then((response) => {
					if (response.status == 401) {

					} else {
						return response.json()
					}
				})
				.then((responseData) => {
					console.log("res: ", responseData);
					if (!responseData.message && !responseData.error && responseData.length != 0) {
						if (!responseData.Error) {
							perfromLogin();
						} else {
							// have no account
							// this.setState({ tab: 'register' });
							errors["error"] = "Your are not registered , Please Register.";
							setstate((prevState) => ({
								...prevState, errors: errors
							}));
							event.preventDefault();
						}
					} else if (responseData.error && responseData.error == 'Unauthorised') {
						errors["error"] = "Your login session has expired, Please login again.";
						setstate((prevState) => ({
							...prevState, errors: errors
						}));
						event.preventDefault();
					} else {
						setTimeout(() => {
							errors["error"] = "Something went wrong, Please try again after sometime.";
							setstate((prevState) => ({
								...prevState, errors: errors
							}));
						}, 1000);
						event.preventDefault();
					}
				});
		}
		else {
			setstate((prevState) => ({
				...prevState, loginBntLoader: false,
			}));
			errors["error"] = "Please enter valid mobile number";
			setstate((prevState) => ({
				...prevState, errors: errors
			}));
		}
	}



	function timer() {
		let t = state.timer - 1;
		if (t == 0) {
			setstate((prevState) => ({
				...prevState,
				resend: false,
				timer: 60,
			}))
			clearInterval(Timer);
		} else {
			setstate((prevState) => ({
				...prevState, timer: t
			}));
		}
	}

	/**
	 * This function verify OTP
	 * @param null
	 * @returns return firebase user
	 */
	function onOTPVerify() {
		setstate((prevState) => ({
			...prevState, verified: true
		}));
		var errors = {};

		if (state.code !== '') {
			return state.confirmationResult.confirm(state.code).then((result) => {
				// User signed in successfully.
				const user = result.user;
				setstate((prevState) => ({
					...prevState, firebaseUser: user
				}));
				_onGetUserCredential();
				// ...
			})
				.catch((error) => {
					console.log(error)
					// User couldn't sign in (bad verification code?)
					errors["error"] = "Please enter valid OTP";
					setstate((prevState) => ({
						...prevState, errors: errors, verified: false
					}));
				});
		}
		else {
			errors["error"] = "Please enter valid OTP";
			return setstate((prevState) => ({
				...prevState, errors: errors, verified: false
			}));
		}
	}

	/**
	 * @param null
	 * @returns token
	 * @description Get token from firebase usre
	 */
	function getGoogleAuthToken() {

		return state.user.getIdToken().then(async function (idToken) {
			return await idToken;
		});
	}

	/**
	 * @param null
	 * @description This function get user and Bearer Token
	 * @returns user detail
	 */
	function perfromLogin() {
		setLoading(true)
		let user = state.firebaseUser;
		let errors = {};
		const Token = getGoogleAuthToken();
		let MobileNumber = state.country_code + state.mobile_no;

		let details = {
			'GoogleAuthToken': Token,
			'PhoneNumber': MobileNumber,
			'client_id': url.ClientId,
			'client_secret': url.client_secret,
			'scope': url.scope,
			'response_type': url.response_type,
			'grant_type': url.grant_type
		};

		let formBody = [];
		for (let property in details) {
			let encodedKey = encodeURIComponent(property);
			let encodedValue = encodeURIComponent(details[property]);
			formBody.push(encodedKey + "=" + encodedValue);
		}
		formBody = formBody.join("&");

		fetch(url.urlShort + "connect/token",
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: formBody,
			})
			.then((response) => response.json())
			.then((responseData) => {
				setLoading(false)
				setstate((prevState) => ({
					...prevState, verified: false
				}));
				if (!responseData.error && (responseData.error == undefined || !responseData.error == 'invalid_client')) {
					localStorage.setItem('user', JSON.stringify(responseData.User));
					localStorage.setItem('GoogleAuthToken', Token);
					localStorage.setItem('token', "Bearer " + responseData.access_token);
					localStorage.setItem('refresh_token', responseData.refresh_token);

					const RoleName = responseData.User?.RoleName || "";
					const hasTIP = RoleName?.includes("TIPAdmin");
					console.log("hasTIP: ", hasTIP);
					// condition to check the user is for TipCheck or BeachCheck
					if (hasTIP) {
						// TipAdmin means tip use so mode will be TIP
						let role = RoleName.split(",").pop("TIPAdmin");
						role = role === "TIPAdmin" ? "" : role;
						localStorage.setItem('mode', "TIP");
						localStorage.setItem('role', role);
						console.log("mode set: tip")
					}
					else {
						//  if not TipAdmin then it will be BEACHCHECK
						localStorage.setItem('mode', "BEACHCHECK");
						localStorage.setItem('role', RoleName);
						console.log("mode set: beachcheck")
					}

					setstate((prevState) => ({
						...prevState, logging: false, isInformationPageLoading: false
					}));
					dispatch(loginUser(responseData.User, props.router.navigate));
					

				} else {
					setstate((prevState) => ({
						...prevState, logging: false
					}));

					setTimeout(() => {
						errors["error"] = "Something went wrong, Please try again after sometime.";
						setstate((prevState) => ({
							...prevState, errors: errors
						}));
					}, 1000);
				}
			}).catch((err) => {
				console.log("err", err);
				errors["error"] = "Something went wrong, Please try again after sometime.";
				setstate((prevState) => ({
					...prevState, errors: errors
				}));
			});

	}

	// Sangathan
	function _onGetUserCredential() {
		setLoading(true)
		const { mobile_no, firebaseUser } = this.state;
		const Token = getGoogleAuthToken();
		// const { signin } = this.context;
		if (!mobile_no) {
			//   Alert.alert(
			// 	'Mobile No Required',
			// 	'Mobile No is Invalid.',
			// 	[{ text: 'Okay', onPress: () => { } }],
			// 	{ cancelable: false }
			//   );
			return true;
		}
		setstate((prevState) => ({
			...prevState, isOTPSubmit: true
		}));
		let urlPath = "auth/login";

		fetch(url.url + urlPath, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				firebaseToken: Token,
				mobileNo: mobile_no,
			})
		}).then((response) => response.json())
			.then((responseData) => {
				setLoading(false)
				// console.log("Login responseData", responseData)
				if (responseData.status && "response" in responseData) {
					const RoleName = responseData.response.User?.role || "Member";
					const userData = !!responseData?.response?.User?.sangathanId ? { ...responseData?.response?.User, sangathanDetails: responseData?.response?.User?.sangathanId, sangathanId: responseData?.response?.User.sangathanId._id } : responseData?.response?.User;
					// console.log(userData)

					localStorage.setItem('user', JSON.stringify(userData));
					localStorage.setItem('GoogleAuthToken', Token);
					localStorage.setItem('token', "Bearer " + responseData?.response?.token);
					localStorage.setItem('role', RoleName);
					setstate((prevState) => ({
						...prevState, logging: false, isInformationPageLoading: false
					}));

					dispatch(loginUser(userData, props.router.navigate));
					

					// window.location = '/';
					//   return signin({
					// 	email: responseData?.response?.User?.email || "",
					// 	user: userData || {},
					// 	userToken: "Bearer " + responseData.response.token,
					// 	role: RoleName
					//   })
				} else if (!responseData.status && responseData.error && typeof responseData.error == "object") {
					//   Alert.alert(
					// 	'Error',
					// 	responseData.error,
					// 	[{ text: 'Okay', onPress: () => { this.setState({ isOTPSubmit: false, otp: "" }); } }],
					// 	{ cancelable: false })
				} else {
					//   Alert.alert(
					// 	'Oops!',
					// 	'Something went wrong, Please try again after sometime.',
					// 	[{ text: 'Okay', onPress: () => { } }],
					// 	{ cancelable: false }
					//   );
				}
			}).catch(error => {
				console.log("Error : ", error);
				// Alert.alert(
				//   'Oops!',
				//   'Something went wrong, Please try again after sometime.',
				//   [{ text: 'Okay', onPress: () => { this.setState({ isOTPSubmit: false }); } }],
				//   { cancelable: false }
				// );
			}).finally((err) => {
				// this.setState({ isOTPSubmit: false });
			});
	}

	/**
	 * This function get Organization
	 * @param null
	 * @returns array
	 * @description This function get all organization and set in OrganizationList variable
	 */
	function getOrganization() {
		let errors = {};

		fetch(url.url + 'MasterData/Organization', {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				// 'Authorization': token
			},
		})
			.then((response) => response.json())
			.then((responseData) => {
				// console.log("responseData =>", responseData)
				if (!("Error" in responseData)) {
					const OrganizationList = responseData.filter((org) => {
						org.label = org.Name;
						org.value = org.ID;
						return org;
					})
					setstate((prevState) => ({ ...prevState, OrganizationList: OrganizationList, loading: false }))
				} else if (responseData.error && responseData.message) {
					errors["error"] = responseData.message;
					setstate((prevState) => ({ ...prevState, errors: errors }));
				}
				else {
					errors["error"] = "Server Error, Please try after sometime.";
					setstate((prevState) => ({ ...prevState, errors: errors }));
				}
			}).catch(err => {
				console.log("error =>", err);
			})
	}
	function numberCheckAPI(event) {
		setLoading(true)
		setstate((prevState) => ({ ...prevState, loginBntLoader: true }));

		const Body = JSON.stringify({
			'countryCode': state.country_code,
			"mobileNo": state.mobile_no.trim(),
			'client': url.AUTH_APP_NAME,
			'client_secret': url.AUTH_SECRET
		})
		let urlPath = "check-mobile-registered";
		fetch(url.AUTH_URL + urlPath, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: Body,
		}).then((response) => response.json())
			.then((responseData) => {
				// console.log(responseData);
				setLoading(false)
				if (responseData.status && responseData.auth) {
					sendOTP()
				} else if (responseData.error && responseData.error && typeof responseData.error != "object") {
					let errors = {}
					errors.error = responseData.error;
					setstate((prevState) => ({ ...prevState, errors: errors, isNumberVerified: true, loginBntLoader: false }));
				} else if (typeof responseData.error === "object") {
					alert(
						'Error!  \n\n ' + responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
					)
					let errors = {}
					errors.mobileNo = "Something went wrong.";
					setstate((prevState) => ({ ...prevState, errors: errors, isNumberVerified: false, loginBntLoader: false }));
				} else {
					alert(
						'Oops! \n\n Something went wrong, Please try again after sometime.'
					)
					let errors = {}
					errors.mobileNo = "Something went wrong.";
					setstate((prevState) => ({ ...prevState, errors: errors, isNumberVerified: false, loginBntLoader: false }));
				}

			}).catch(error => {
				console.log("err", error);
				alert(
					'Something went wrong, Please try again after sometime.'
				)
				let errors = {}
				errors.mobileNo = "Something went wrong.";
				setstate((prevState) => ({ ...prevState, errors: errors, isNumberVerified: false, loginBntLoader: false }));
			}).finally(final => {

			});
	}

	function sendOTP() {
		setLoading(true)

		const Body = JSON.stringify({
			'countryCode': state.country_code,
			"mobileNo": state.mobile_no.trim(),
			'client': url.AUTH_APP_NAME,
			'client_secret': url.AUTH_SECRET
		})
		let urlPath = "send-otp";
		fetch(url.AUTH_URL + urlPath, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: Body,
		}).then((response) => response.json())
			.then((responseData) => {
				setLoading(false)
				if (responseData.status) {

					setstate((prevState) => ({ ...prevState, isOTP: true, loginBntLoader: false }))
				} else if (responseData.error && responseData.error && typeof responseData.error != "object") {
					setstate((prevState) => ({ ...prevState, errors: {}, isNumberVerified: true, loginBntLoader: false }));
				} else if (typeof responseData.error === "object") {
					alert(
						'Error!  \n\n ' + responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
					)
					let errors = {}
					errors.mobileNo = "Something went wrong.";
					setstate((prevState) => ({ ...prevState, errors: errors, isNumberVerified: false, loginBntLoader: false }));
				} else {
					alert(
						'Oops! \n\n Something went wrong, Please try again after sometime.'
					)
					let errors = {}
					errors.mobileNo = "Something went wrong.";
					setstate((prevState) => ({ ...prevState, errors: errors, isNumberVerified: false, loginBntLoader: false }));
				}

			}).catch(error => {
				console.log("err", error);
				alert(
					'Something went wrong, Please try again after sometime.'
				)
				let errors = {}
				errors.mobileNo = "Something went wrong.";
				setstate((prevState) => ({ ...prevState, errors: errors, isNumberVerified: false, loginBntLoader: false }));
			}).finally(final => {

			});
	}

	function CheckOTP() {
		setLoading(true)
		const Body = JSON.stringify({
			'countryCode': state.country_code,
			"mobileNo": state.mobile_no.trim(),
			"otp": state.otp,
			'client': url.AUTH_APP_NAME,
			'client_secret': url.AUTH_SECRET
		})
		let urlPath = "verify-otp";
		fetch(url.AUTH_URL + urlPath, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			body: Body,
		}).then((response) => response.json())
			.then((responseData) => {
				setLoading(false)
				if (responseData.status) {
					const Token = responseData.response.token
					const RoleName = responseData.response.User?.role || "Member";
					const userData = !!responseData?.response?.User?.sangathanId ? { ...responseData?.response?.User, sangathanDetails: responseData?.response?.User?.sangathanId, sangathanId: responseData?.response?.User.sangathanId._id } : responseData?.response?.User;
					// console.log(userData)

					localStorage.setItem('user', JSON.stringify(userData));
					localStorage.setItem('GoogleAuthToken', Token);
					localStorage.setItem('token', "Bearer " + responseData?.response?.token);
					localStorage.setItem('role', RoleName);

					setstate((prevState) => ({ ...prevState, logging: false, isInformationPageLoading: false }));
					// window.location = '/';
					var initialValues= {
						email: userData.firstName || '',
						mobile_no: userData.mobileNo || '',
						country_code: userData.countryCode,
					   //password: "123456" || '',
					   //mobile_no: "admin@xpertnest.com" || '',
					   password: responseData?.response?.token || '',
					 }
					dispatch(loginUser(initialValues, props.router.navigate));

				} else if (responseData.error && responseData.error && typeof responseData.error != "object") {
					setstate((prevState) => ({ ...prevState, errors: {}, isNumberVerified: true, loginBntLoader: false }));
				} else if (typeof responseData.error === "object") {
					alert(
						'Error!  \n\n ' + responseData.error.length > 0 ? responseData.error[0].message : 'Something went wrong, Please try again after sometime.'
					)
					let errors = {}
					errors.mobileNo = "Something went wrong.";
					setstate((prevState) => ({ ...prevState, errors: errors, isNumberVerified: false, loginBntLoader: false }));
				} else {
					alert(
						'Oops! \n\n Something went wrong, Please try again after sometime.'
					)
					let errors = {}
					errors.mobileNo = "Something went wrong.";
					setstate((prevState) => ({ ...prevState, errors: errors, isNumberVerified: false, loginBntLoader: false }));
				}

			}).catch(error => {
				console.log("err", error);
				alert(
					'Something went wrong, Please try again after sometime.'
				)
				let errors = {}
				errors.mobileNo = "Something went wrong.";
				setstate((prevState) => ({ ...prevState, errors: errors, isNumberVerified: false, loginBntLoader: false }));
			}).finally(final => {

			});
	}
	function handleSignUpValidation() {

	}
	function onSignUp() {
		let errors = {};
		const authToken = getGoogleAuthToken();

		let MobileNumber = state.country_code + state.mobile_no;
		if (handleSignUpValidation()) {
			setstate((prevState) => ({ ...prevState, registering: true }));
			const details = JSON.stringify({
				'GoogleAuthToken': authToken,
				'PhoneNumber': MobileNumber,
				'Name': state.name,
				'Email': state.email,
				'OrganizationID': state.orgId
			});

			fetch(url.url + "Account/UserRegister",
				{
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
					body: details,
				})
				.then((response) => response.json())
				.then((responseData) => {
					if (!responseData.message && !responseData.error && responseData.length != 0) {
						if (responseData.Result != 'UnAuthorized') {
							localStorage.setItem('user', JSON.stringify(responseData.Users));

							const RoleName = responseData.Users?.RoleName || "";
							let hasTIP = RoleName.includes("TIPAdmin");
							let role = hasTIP && RoleName.split(",").pop("TIPAdmin");
							role = role === "TIPAdmin" ? "" : role;
							localStorage.setItem('role', role);
							console.log("hasTIP: ", hasTIP);
							// condition to check the user is for TipCheck or BeachCheck
							if (hasTIP) {
								// TipAdmin means tip use so mode will be TIP
								localStorage.setItem('mode', "TIP");
								console.log("mode set: tip")
							}
							else {
								//  if not TipAdmin then it will be BEACHCHECK
								localStorage.setItem('mode', "BEACHCHECK");
								console.log("mode set: beachcheck")
							}

							localStorage.setItem('token', "Bearer " + responseData.Users.Token);
							setstate((prevState) => ({ ...prevState, registering: false }));
							window.location = '/';
						} else {
							setstate((prevState) => ({ ...prevState, registering: false }));
						}
					} else if (responseData.error && responseData.error == 'Unauthorised') {
						errors["error"] = "Your login session has expired, Please login again.";
						setstate((prevState) => ({ ...prevState, errors: errors }));
					} else {
						setTimeout(() => {
							errors["error"] = "Something went wrong, Please try again after sometime.";
							setstate((prevState) => ({ ...prevState, errors: errors }));
						}, 1000);
					}
				});
		}
	}

	function numberHandler(event) {
		let phone = event.target.value;
		setstate((prevState) => ({ ...prevState, mobile_no: phone.replace(/[^0-9]/g, '') }));
	}

	function resendOTP() {
		window.location.reload();
	}

	function onChangeCheckbox(event, name) {
		setstate((prevState) => ({
			...prevState,
			[name]: event.target.checked
		}))
	}

	function changeLoginMode(event, name) {
		let val = event.target.checked;
		// if(val)
		// {
		console.log("name: ", name);
		setstate((prevState) => ({ ...prevState, loginMode: name, errors: {} }))
		// }
	}

	function loginUserPwd() {
		setstate((prevState) => ({ ...prevState, loginBntLoader: true }));

		if (state.RememberMe && state.username !== "") {
			localStorage.username = state.username
			localStorage.password = state.password
			localStorage.checkbox = state.RememberMe
		}

		let errors = {};
		let formIsValid = true;
		console.log("uname: ", state.username, "pass: ", state.password);
		if (!state.username || state.username.trim().length == 0) {
			formIsValid = false;
			errors["error"] = "enter valid email";
			setstate((prevState) => ({ ...prevState, errors: errors, loginBntLoader: false }));
			return formIsValid;
		}

		if (!state.password || state.password.trim().length == 0) {
			formIsValid = false;
			errors["error"] = "enter valid password";
			setstate((prevState) => ({ ...prevState, errors: errors, loginBntLoader: false }));
			return formIsValid;
		}

		let details = {
			'grant_type': url.grant_type,
			'client_id': url.ClientId,
			'client_secret': url.client_secret,
			'scope': url.scope,
			'response_type': url.response_type,
			'Email': state.username,
			'Password': state.password
		};

		let formBody = [];
		for (let property in details) {
			let encodedKey = encodeURIComponent(property);
			let encodedValue = encodeURIComponent(details[property]);
			formBody.push(encodedKey + "=" + encodedValue);
		}
		formBody = formBody.join("&");

		fetch(url.urlShort + "connect/token",
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: formBody,
			})
			.then((response) => response.json())
			.then((responseData) => {
				console.log(responseData)
				setstate((prevState) => ({ ...prevState, verified: false }));
				if (!responseData.error && (responseData.error == undefined || !responseData.error == 'invalid_client')) {
					localStorage.setItem('user', JSON.stringify(responseData.User));
					localStorage.setItem('token', "Bearer " + responseData.access_token);
					localStorage.setItem('refresh_token', responseData.refresh_token);

					const RoleName = responseData.User?.RoleName || "";
					const hasTIP = RoleName?.includes("TIPAdmin");
					console.log("hasTIP: ", hasTIP);
					// condition to check the user is for TipCheck or BeachCheck
					if (hasTIP) {
						// TipAdmin means tip use so mode will be TIP
						let role = RoleName.split(",").pop("TIPAdmin");
						role = role === "TIPAdmin" ? "" : role;
						localStorage.setItem('mode', "TIP");
						localStorage.setItem('role', role);
						console.log("mode set: tip")
					}
					else {
						//  if not TipAdmin then it will be BEACHCHECK
						localStorage.setItem('mode', "BEACHCHECK");
						localStorage.setItem('role', RoleName);
						console.log("mode set: beachcheck")
					}

					setstate((prevState) => ({ ...prevState, logging: false, isInformationPageLoading: false }));
					window.location = '/';

				}
				else if (responseData.error) {
					console.log("here error: ", responseData.error)
					errors["error"] = responseData.error;
					return setstate((prevState) => ({ ...prevState, errors: errors }));
				}
				else {
					setstate((prevState) => ({ ...prevState, logging: false }));

					setTimeout(() => {
						errors["error"] = "Something went wrong, Please try again after sometime.";
						setstate((prevState) => ({ ...prevState, errors: errors }));
					}, 1000);
				}
			})
			.catch((err) => {
				console.log("err", err);
				errors["error"] = "Something went wrong, Please try again after sometime.";
				setstate((prevState) => ({ ...prevState, errors: errors }));
			})
			.finally(final => {
				setstate((prevState) => ({ ...prevState, loginBntLoader: false }));
			});
	}

	function sendForgotPasswordMail(){
		setstate((prevState) => ({ ...prevState, mailLoader: true }));


		let errors = {};
		let formIsValid = true;
		console.log("uname: ", state.email);
		if (!state.email || state.email.trim().length == 0) {
			formIsValid = false;
			errors["error"] = "enter valid email";
			setstate((prevState) => ({ ...prevState, errors: errors, mailLoader: false }));
			return formIsValid;
		}

		let url = `Account/ForgotPasswordByEmail?EmailID=${state.email}&ClientID=PlaceCheckPro`;
		fetch(url.url + url,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.then(
				(response) => {
					if (response.status == 400) {
						errors["error"] = "User not found";
						setstate((prevState) => ({ ...prevState, errors: errors }))
					} else {
						return response.json();
					}
				}
			)
			.then((responseData) => {
				console.log(responseData);
				alert('Reset password sent you via mail.');
				setstate((prevState) => ({ ...prevState, success: true, successMsg: 'Reset password sent you via mail.', email: '', tab: 'login' }));
			})
			.catch((err) => {
				console.log("err", err);
				errors["error"] = "Something went wrong, Please try again after sometime.";
				setstate((prevState) => ({ ...prevState, errors: errors, mailLoader: false }));
			})
			.finally(final => {
				setstate((prevState) => ({ ...prevState, mailLoader: false }));
			});
	}

	return (
		<>

			<React.Fragment>
			{isLoading ? <Spinners /> :
				<div className="account-pages my-5 ">
					
						<Row className="justify-content-center pt-3 col-12">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<CardBody className="pt-0">

										<h3 className="text-center">
											<Link to="/" className="d-block auth-logo">
												<img src={logoVSP} alt="" height="70" className="auth-logo-dark" />
												<img src={logoVSP} alt="" height="70" className="auth-logo-light" />
											</Link>
										</h3>
										<div className="login-form text-center p-7 position-relative overflow-hidden">
											{/* <!--begin::Login Header--> */}
											<div className="d-flex flex-center mb-8">
												{/* <a href="#">
										<img src="assets/kent_logo.png" className="max-h-120px" alt="" />
									</a> */}
											</div>

											{/* <!--end::Login Header--> */}
											{
												state.tab === 'login' &&

												//* <!--begin::Login Sign in form--> */
												<div className="login-signin">
													{
														state.isOTP ?
															<div className="mb-10">
																<h3>Verify OTP</h3>
																<div className="text-muted font-weight-bold">We have sent you access code via SMS for<br />mobile number verification</div>
															</div>
															:
															<div className="mb-10">
																<h1>VUF Admin Portal</h1>
																<br />
																<h4>Sign In</h4>
																<div className="text-muted font-weight-bold">Enter your details to login to your account:</div>
																<br />
																{/* <div className="form-inline" style={{ justifyContent: 'space-around' }}>
														<div className="checkbox-inline">
															<label className="checkbox m-0 text-muted">
																<input type="checkbox" name="mobile" checked={loginMode == "mobile" ? 'checked' : ''} value={loginMode == "mobile"} onChange={e => this.changeLoginMode(e, 'mobile')} />
																<span></span>Mobile Number</label>
														</div>
														<div className="checkbox-inline">
															<label className="checkbox m-0 text-muted">
																<input type="checkbox" name="email" checked={loginMode == "email" ? 'checked' : ''} value={loginMode == "email"} onChange={e => this.changeLoginMode(e, 'email')} />
																<span></span>Email</label>
														</div>
													</div> */}
															</div>
													}

													{
														state.loginMode == "mobile" ?
															<>
																{/* <div id="recaptcha-container" ref={ref => recaptchaWrapperRef = ref} ></div> */}
																{
																	state.isOTP ?

																		<>
																			{state.errors.error &&
																				<div className="alert alert-danger text-left" role="alert">
																					<span className='pl-3'>{state.errors.error}</span>
																				</div>
																			}
																			<div className="">
																				<input className="form-control h-auto form-control-solid" type="text" placeholder="Enter OTP" name="otp" autoComplete="off"
																					onChange={(event) => { setstate((prevState) => ({ ...prevState, otp: event.target.value })) }}
																					value={state.otp} />
																			</div>
																			{
																				state.verified ?
																					<button type="button" className="btn btn-primary font-weight-bold mt-3"><Spinners color={'#fff'} /></button>
																					:
																					<div style={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', display: 'flex' }}>
																						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
																							<button type="button" className="btn btn-primary font-weight-bold mt-3" onClick={(event) => resendOTP(event)} disabled={state.resend}>Resend</button>
																							<div>{state.timer} Seconds</div>
																						</div>
																						<div>
																							<button type="button" className="btn btn-primary font-weight-bold mt-3" onClick={(event) => CheckOTP(event)}>Verify</button>
																						</div>
																					</div>

																			}

																		</>
																		: <>
																			{state.errors.error &&
																				<div className="alert alert-danger text-left" role="alert">
																					<span className='pl-3'>{state.errors.error}</span>
																				</div>
																			}

																			<div className="row">
																				<div className="form-group " style={{ width: '20%', marginRight: '2%' }}>
																					<input className="form-control h-auto form-control-solid " type="text" placeholder="+91" name="country_code" autoComplete="off"
																						onChange={(event) => { setstate((prevState) => ({ ...prevState, country_code: event.target.value })) }}
																						value={state.country_code} style={{ width: '100%' }} />
																				</div>
																				<div className="form-group " style={{ width: '78%' }}>
																					<input className="form-control h-auto form-control-solid " type="text" placeholder="Mobile no" name="mobile_no" autoComplete="off" onChange={(event) => numberHandler(event)} value={state.mobile_no} style={{ width: '100%' }} />
																				</div>
																			</div>


																			{/* <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
																	<div className="checkbox-inline">
																		<label className="checkbox m-0 text-muted">
																			<input type="checkbox" name="remember" checked={this.state.RememberMe} onChange={e => this.onChangeCheckbox(e, 'RememberMe')} />
																			<span></span>Remember me</label>
																	</div>

																</div> */}
																			{
																				state.loginBntLoader ? <button type="button" className="btn btn-primary font-weight-bold"><Spinners color={'#fff'} /></button> :
																					<button type="button" className="btn btn-primary font-weight-bold " onClick={(event) => numberCheckAPI(event)}>Send OTP</button>
																			}
																		</>
																}
															</>
															:
															<>
																{state.errors.error &&
																	<div className="alert alert-danger text-left" role="alert">
																		<span className='pl-3'>{state.errors.error}</span>
																	</div>
																}
																<div className="form-group mb-5 " style={{ width: '100%' }}>
																	<input className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Email" name="username" autoComplete="off"
																		onChange={(event) => { setstate((prevState) => ({ ...prevState, username: event.target.value })) }}
																		value={state.username} style={{ width: '100%' }} />
																</div>
																<div className="form-group mb-5 ">
																	<input className="form-control h-auto form-control-solid py-4 px-8" type="password" placeholder="Password" name="password"
																		onChange={(event) => { setstate((prevState) => ({ ...prevState, password: event.target.value })) }}
																		value={state.password} style={{ width: '100%' }} />
																</div>

																<div className="form-group d-flex flex-wrap justify-content-between align-items-center">
																	<div className="checkbox-inline">
																		<label className="checkbox m-0 text-muted">
																			<input type="checkbox" name="remember" checked={state.RememberMe} onChange={e => onChangeCheckbox(e, 'RememberMe')} />
																			<span></span>Remember me</label>
																	</div>
																	<a href="javascript:;" id="kt_login_forgot" className="text-muted text-hover-primary" onClick={() => { setstate((prevState) => ({ ...prevState, tab: 'forgotPassword' })) }}>Forget Password ?</a>
																</div>
																{
																	state.loginBntLoader ? <button type="button" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"><Spinners color={'#fff'} /></button> :
																		<button type="button" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4" onClick={() => { loginUserPwd() }}>Sign in</button>
																}
															</>
													}
												</div>
												//* <!--end::Login Sign in form--> */}
											}
											{
												state.tab === 'register' &&
												//* <!--begin::Login Sign up form--> *
												<div className="login-signup">
													<div className="mb-10">
														<h3>Sign Up</h3>
														<div className="text-muted font-weight-bold">Enter your details to create your account</div>
													</div>
													<form className="" method="POST">
														<div className="form-inline">
															<div className="form-group mb-5" style={{ width: '20%', marginRight: '2%' }}>
																<input className="form-control h-auto form-control-solid py-4 px-8" disabled type="text" placeholder="+91" name="country_code" autoComplete="off"
																	onChange={(event) => { setstate((prevState) => ({ ...prevState, country_code: event.target.value })) }}
																	value={state.country_code} style={{ width: '100%' }} />
															</div>
															<div className="form-group mb-5" style={{ width: '78%' }}>
																<input className="form-control h-auto form-control-solid py-4 px-8" disabled type="text" placeholder="Mobile no"
																	name="mobile_no" autoComplete="off"
																	onChange={(event) => { setstate((prevState) => ({ ...prevState, mobile_no: event.target.value })) }}
																	value={state.mobile_no} style={{ width: '100%' }} />
															</div>
															{state.errors.mobile_no && <span className="form-text  text-danger text-left">{state.errors.mobile_no}</span>}
														</div>
														<div className="form-group mb-5">
															<input className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="name" name="name" autoComplete="off"
																onChange={(event) => { setstate((prevState) => ({ ...prevState, name: event.target.value })) }}
																value={state.name} style={{ width: '100%' }} />
															{state.errors.name && <span className="form-text  text-danger text-left">{state.errors.name}</span>}
														</div>
														<div className="form-group mb-5">
															<input className="form-control h-auto form-control-solid py-4 px-8" type="text" placeholder="Email" name="email" autoComplete="off"
																onChange={(event) => { setstate((prevState) => ({ ...prevState, email: event.target.value })) }}
																value={state.email} style={{ width: '100%' }} />
															{state.errors.email && <span className="form-text  text-danger text-left">{state.errors.email}</span>}
														</div>

														<div className="form-group mb-5">
															<select className="form-control form-control-solid py-4 px-8 h-auto" name='orgId' value={state.orgId}
																onChange={(event) => { setstate((prevState) => ({ ...prevState, orgId: event.target.value })) }}>
																<option value=''>Select Organization</option>
																{
																	state.OrganizationList.map((org, index) => {
																		return (
																			<option key={index} value={org.value}>{org.label}</option>
																		)
																	})
																}
															</select>
															{state.errors.orgId && <span className="form-text  text-danger text-left">{state.errors.orgId}</span>}
														</div>

														<div className="form-group d-flex flex-wrap flex-center mt-10">
															{
																state.registering ?
																	<button type="button" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2"><Spinners color='#fff' /></button>
																	:
																	<button type="button" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2" onClick={() => onSignUp()}>Sign Up</button>
															}
														</div>
													</form>
												</div>
											}

											{
												state.tab === 'forgotPassword' &&
												<>
													{state.errors.error &&
														<div className="alert alert-danger text-left" role="alert">
															<span className='pl-3'>{state.errors.error}</span>
														</div>
													}
													{state.success &&
														<div className="alert alert-success text-left" role="alert">
															<span className='pl-3'>{state.successMsg}</span>
														</div>
													}
													<div className="login-forgot">
														<div className="mb-10">
															<h3>Forgotten Password ?</h3>
															<div className="text-muted font-weight-bold">Enter your email to reset your password</div>
														</div>

														<div className="form-group mb-10 fv-plugins-icon-container">
															<input className="form-control form-control-solid h-auto py-4 px-8" type="text" placeholder="Email" name="email" autoComplete="off"
																onChange={(event) => { setstate((prevState) => ({ ...prevState, email: event.target.value })) }}
																value={state.email} style={{ width: '100%' }} />
															<div className="fv-plugins-message-container"></div></div>
														<div className="form-group d-flex flex-wrap flex-center mt-10">
															<button id="kt_login_forgot_submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2" onClick={() => { sendForgotPasswordMail() }}>Request</button>
															{/* <button id="kt_login_forgot_cancel" className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-2">Cancel</button> */}
														</div>
														<div></div>
													</div>
												</>
											}

											{/* <a href={'/signup'} className="menu-link" >
									<span style={{ fontSize: 16 }}>Sign up</span>
								</a> */}
										</div>


										{/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 20, }}>
											<h4 style={{ fontSize: '1.100rem' }}>Powered By</h4>
											<img src="assets/images/Logo/xpertnest-logo.png" className="max-h-120px" alt="" height={50} style={{ marginTop: 0, }} />
										</div> */}
									</CardBody>
								</Card>

								<div className="mt-5 text-center">
									{/* <p>Don't have an account ? <Link to="/register" className="text-primary"> Signup Now </Link></p> */}
									© {new Date().getFullYear()} VUF Admin Portal
									{/* © {new Date().getFullYear()} VPS 
                <span className="d-none d-sm-inline-block"> - Crafted with <i className="mdi mdi-heart text-danger"></i> by Xpernest.</span> */}
								</div>
							</Col>
						</Row>
					
				</div>
			}
			</React.Fragment>
		</>
	);
}
export default withRouter(withTranslation()(Login));

