import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { connect } from "react-redux";
import Select from "react-select"
import { setBreadcrumbItems } from "../../store/actions";

import Spinners from "components/Common/Spinner"
import { Value } from "sass";
import * as url from "../../helpers/url_helper"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { string } from "prop-types";
import { MDBDataTable } from "mdbreact"
import { Link } from 'react-router-dom';
import moment from 'moment';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx'

const UserList = (props) => {
    document.title = "User List - VUF";

    const [userdataList, setuserdataList] = useState([]);
    const [DateRange, setDateRange] = useState({});
    const [SelectUser, setSelectUser] = useState({});
    const [selectionRange, setselectionRange] = useState({});

    const [countries, setcountries] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [districtList, setdistrictList] = useState([]);
    const [cityList, setcityList] = useState([]);

    const [selectedCity, setselectedCity] = useState({});
    const [selectedCountry, setselectedCountry] = useState({});
    const [selectedDistrict, setselectedDistrict] = useState({});
    const [selectedState, setselectedState] = useState({});


    const [cityCode, setcityCode] = useState({});
    const [districtCode, setdistrictCode] = useState({});
    const [stateCode, setstateCode] = useState({});
    const [countryDataCode, setcountryDataCode] = useState({});


    const breadcrumbItems = [
        { title: "VUF", link: "#" },
        { title: "UserList", link: "#" }
    ]

    const Values = {
        copy: [],
    };

    const UserType = [
        {
            options: [
                { label: "VUF Main App", value: "VUFAPP" },
                { label: "VUF GBN App", value: "VUFGBN" },
                { label: "VUF Sangathan", value: "SANGATHAN_BETA" }
            ]
        }
    ]
    const [isLoading, setLoading] = useState(false)
    const [form, setValues] = useState(Values)
    const [copy, setcopy] = useState([]);
    useEffect(() => {
        props.setBreadcrumbItems('List', breadcrumbItems)

    })

    useEffect(() => {
        setSelectUser({ label: "VUF Main App", value: "VUFAPP" })
        getusers(true, 'VUFAPP', selectionRange.startDate, selectionRange.endDate, "", "", "", "");

        // Perform your componentDidMount logic here


    }, []);


    function getusers(loading, UserType, startDate, endDate, Country, state, district, city) {
        // getReportDashboard
        setLoading(true)
        console.log(UserType)
        let urlPath = "appmanager/appusers/"


        fetch(url.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                UserType: UserType,
                country: (Country.label == "" || Country.label == "--Select--") ? "" : Country.label,
                city: (city.label == "" || city.label == "--Select--") ? "" : city.label,
                state: (state.label == "" || state.label == "--Select--") ? "" : state.label,
                distict: (district.label == "" || district.label == "--Select--") ? "" : district.label,
                startDate: startDate || new Date(),
                endDate: endDate || new Date(),
                village: ""
            })
        }).then((response) => response.json())
            .then((responseData) => {


                if (responseData.status && responseData.data != undefined) {
                    var userList = responseData.data;
                    setuserdataList(responseData.data);
                    let datacopy = [];
                    userList.forEach(d => datacopy.push({ 'Sr.no.': userList.indexOf(d) + 1, FirstName: d.firstName, LastName: d.lastName, nickname: d.nickname, MobileNo: d.countryCode + d.mobileNo, profession: d.profession, otherProfession: d.otherProfession, Address: d.street_address, country: d.country, state: d.state, district: d.district, city: d.city, village: d.village, area: d.area, dob: d.dob, age: d.age, remarks: d.remarks }))
                    setcopy(datacopy)
                    if (Country == "" && city == "" && state == "" && district == "") {
                        onGetCountryList()
                    }


                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                }
                setLoading(false)
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
            }).finally(final => {

            });

    }

    function onGetCountryList() {

        let urlPath = "auth/CountryList"

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;

                    let countries = [];
                    countries.push({ label: "--Select--", value: "" })
                    data.map((c, i) => {
                        countries.push({ label: c.country_name, value: c.id })
                    })
                    console.log(countries)
                    if (userdataList.length > 0) {

                        var countr = userdataList.map(function (a) { return a.country; }).filter((item, i, arr) => arr.findIndex((t) => t === item) === i);
                        console.log(countr)

                        countries = countries.filter(s => countr.filter(a => a != undefined && a == s.label).length > 0)
                        console.log(countries)
                    }
                    console.log(UserType)
                    const cot = [{ "options": countries }]
                    console.log(cot)

                    setcountries(cot)

                    setselectedCity("")
                    setselectedDistrict("")
                    setselectedState("")

                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {

            });
    }
    function onGetStateList(data) {
        setcountryDataCode(data)
        if (data != null && data != undefined && data != '') {

            let urlPath = "auth/StatesList/" + data

            fetch(url.VUF_APP_API + urlPath, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // body: Body,
            }).then((response) => response.json())
                .then(async (responseData) => {
                    // // // console.log("RES ",responseData)
                    if (responseData.status) {
                        var data = responseData.response;

                        let stateList = []
                        stateList.push({ label: "--Select--", value: "" })
                        data.map((c, i) => {
                            stateList.push({ label: c.state_title, value: c.state_id })
                        })
                        console.log(stateList)
                        if (selectedState == "" || selectedState == null) {

                            var seledlis = userdataList.map(function (a) { return a.state; }).filter((item, i, arr) => arr.findIndex((t) => t === item) === i);
                            console.log(seledlis)

                            stateList = stateList.filter(s => seledlis.filter(a => a != undefined && a == s.label).length > 0)
                            console.log(stateList)
                        }
                        const cot = [{ "options": stateList }]
                        setstateList(cot);

                        setselectedDistrict("")
                        setselectedCity("")

                    }
                }).catch(error => {
                    // // console.log("err", error);

                }).finally(final => {
                });
        }
    }
    function onGetDistrictList(data) {
        setstateCode(data)

        let urlPath = "auth/DistrictsList/" + data

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let districtList = []
                    districtList.push({ label: "--Select--", value: "" })
                    data.map((c, i) => {
                        districtList.push({ label: c.district_title, value: c.districtid })
                    })
                    if (selectedDistrict == "" || selectedDistrict == null) {

                        var seledlis = userdataList.map(function (a) { return a.district; }).filter((item, i, arr) => arr.findIndex((t) => t === item) === i);
                        console.log(seledlis)

                        districtList = districtList.filter(s => seledlis.filter(a => a != undefined && a == s.label).length > 0)
                        console.log(districtList)
                    }
                    const cot = [{ "options": districtList }]
                    setdistrictList(cot)
                    selectedCity("")

                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {

            });
    }
    function onGetCitiesList(data) {
        setdistrictCode(data)
        let urlPath = "auth/CitiesList/" + data

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                // // // console.log("RES ",responseData)
                if (responseData.status) {
                    var data = responseData.response;
                    let cityList = []
                    cityList.push({ label: "--Select--", value: "" })
                    data.map((c, i) => {
                        cityList.push({ label: c.name, value: c.id })
                    })
                    if (selectedCity == "" || selectedCity == null) {

                        var seledlis = userdataList.map(function (a) { return a.city; }).filter((item, i, arr) => arr.findIndex((t) => t === item) === i);
                        console.log(seledlis)

                        cityList = cityList.filter(s => seledlis.filter(a => a != undefined && a == s.label).length > 0)
                        console.log(cityList)
                    }
                    const cot = [{ "options": cityList }]
                    setcityList(cot)


                }
            }).catch(error => {
                // // console.log("err", error);

            }).finally(final => {

            });
    }

   function  exportToExcel(data){

        var fileName = "UserList"
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${fileName}.xlsx`);
    };


    function dateval(val) {
        console.log(val)
        setDateRange(val)
        if (val.length == 2) {
            setselectionRange({ startDate: val[0], endDate: val[1] })
            getusers(true, SelectUser.value, val[0], val[1], selectedCountry, selectedState, selectedDistrict, selectedCity)
        } else if (val.length == 0) {
            getusers(false, SelectUser.value, selectionRange.startDate, selectionRange.endDate, selectedCountry, selectedState, selectedDistrict, selectedCity)
        }


    }


    const formattedEventList = userdataList.map(user => ({
        ...user,
        firstName: user.firstName + " " + user.lastName,
        mobileNo: user.countryCode + user.mobileNo,
        dob: user.dob != '' ? moment(user.dob).format("DD-MM-YYYY") : '',
        Address: (user.street_address == undefined || (user.city != null && user.city != undefined && user.city != '' ? user.city + ", " + user.state + ", " + user.country : '')) || '',
        Action: [<Link to={`/UsersDetails/Users/${user._id}`} className='btn btn-info btn-sm  ' > <span className='menu-text' style={{ color: 'white' }}> <span className=' menu-icon fas fa fa-eye' style={{ color: 'white' }}>  </span></span> </Link>]
    }));

    const tableData = {
        columns: [
            {
                label: "Name",
                field: "firstName",
                sort: "asc",
                width: 23,
            },
            {
                label: "Mobile no.",
                field: "mobileNo",
                sort: "asc",
                width: 10,
            },
            {
                label: "DOB",
                field: "dob",
                sort: "asc",
                width: 10,
            },
            {
                label: "Age",
                field: "age",
                sort: "asc",
                width: 5,
            },
            {
                label: "Address",
                field: "Address",
                sort: "asc",
                width: 20,
            },
            {
                label: "Action",
                field: "Action",
                sort: "asc",
                width: 20,
            },

        ],
        rows: formattedEventList,
    };


    return (

        <React.Fragment>

            {isLoading ? <Spinners /> :
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md="9">
                                        <CardTitle className="h4 mb-0">Dashboard</CardTitle>
                                    </Col>
                                    {userdataList.length > 0 ?
                                        <Col md="3">
                                            <button className=" btn btn-success float-right "
                                                onClick={(event) => exportToExcel(copy)} >Export to Excel</button>
                                        </Col>
                                        : ""}
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <Label>Date Range</Label>
                                        <Flatpickr
                                            className="form-control d-block"
                                            placeholder="Select the date range"
                                            value={DateRange}
                                            onChange={(e) => { setDateRange(e); dateval(e) }}
                                            options={{
                                                mode: "range",
                                                dateFormat: "d-m-Y"
                                            }}
                                        />
                                    </div>

                                    <div className='col-md-4'>
                                        <div className="form-group" >
                                            <label>App Type:</label>
                                            <Select classNamePrefix="select2-selection"
                                                value={SelectUser}
                                                onChange={(e) => { setSelectUser(e); getusers(false, e.value, selectionRange.startDate, selectionRange.endDate, "", "", "", "") }}
                                                options={UserType}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <div className='col-md-4'>
                                        <div className="form-group" >
                                            <label>Country / દેશ:</label>
                                            <Select classNamePrefix="select2-selection"
                                                value={selectedCountry}
                                                onChange={(e) => {
                                                    setselectedCountry(e);
                                                    setselectedDistrict({ label: "--Select--", value: "" });
                                                    setselectedCity({ label: "--Select--", value: "" });
                                                    setselectedState({ label: "--Select--", value: "" });
                                                    getusers(false, SelectUser.value, selectionRange.startDate, selectionRange.endDate, e, "", "", ""); onGetStateList(e.value)
                                                }}
                                                options={countries}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-group" >
                                            <label>State / રાજ્ય:</label>
                                            <Select classNamePrefix="select2-selection"
                                                value={selectedState}
                                                onChange={(e) => {
                                                    setselectedState(e);
                                                    setselectedDistrict({ label: "--Select--", value: "" });
                                                    setselectedCity({ label: "--Select--", value: "" });
                                                    getusers(false, SelectUser.value, selectionRange.startDate, selectionRange.endDate, selectedCountry, e, "", ""); onGetDistrictList(e.value)
                                                }}
                                                options={stateList}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-group" >
                                            <label>District / જિલ્લો:</label>
                                            <Select classNamePrefix="select2-selection"
                                                value={selectedDistrict}
                                                onChange={(e) => { setselectedDistrict(e); setselectedCity({ label: "--Select--", value: "" }); getusers(false, SelectUser.value, selectionRange.startDate, selectionRange.endDate, selectedCountry, selectedState, e, ""); onGetCitiesList(e.value) }}
                                                options={districtList}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-group" >
                                            <label>City / શહેર:</label>
                                            <Select classNamePrefix="select2-selection"
                                                value={selectedCity}
                                                onChange={(e) => { setselectedCity(e); getusers(false, SelectUser.value, selectionRange.startDate, selectionRange.endDate, selectedCountry, selectedState, selectedDistrict, e) }}
                                                options={cityList}
                                            />
                                        </div>
                                    </div>
                                    <MDBDataTable responsive striped bordered data={tableData} />
                                </div>

                            </CardBody>

                        </Card>
                    </Col>

                </Row>

            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(UserList);

