import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { connect } from "react-redux";
import Select from "react-select"
import { setBreadcrumbItems } from "../../store/actions";

import Spinners from "components/Common/Spinner"
import { Value } from "sass";
import * as url from "../../helpers/url_helper"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { string } from "prop-types";
import { MDBDataTable } from "mdbreact"
import { Link } from 'react-router-dom';
import moment from 'moment';

const EventList = (props) => {
    document.title = "Event List - VUF";

    const breadcrumbItems = [
        { title: "VUF", link: "#" },
        { title: "EventList", link: "#" }
    ]
    const [isLoading, setLoading] = useState(true)
    const [eventList, seteventList] = useState([]);
    const state = {
        isLoading: false,
        errors: {},
        ResErrors: {},
        isRedirect: false,
        sangathanId: null,
        country_code: '+91',
        ccode: "+91",
        email: "",
        mobileNo: "",
        remark: "",
        assignRole: "",
        firstName: "",
        lastName: "",
        country: "",
        state: "",
        city: "",
        RegistrationLinkToCopy: url.CurrentUrl + "EventRegister/",
        EventAttendantLinkToCopy: url.CurrentUrl + "EventAttendant/",
        QRCodeValue: "ABCD12345",
        locations: [],
        counts: {
            totalUsers: 0,
            mainApp: 0,
            gbnApp: 0,
            sangathanApp: 0,
            eventUsers: 0,
            locationUsers: 0,
        },
        selectionRange: {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },

        showDatePicker: false,
    };



    useEffect(() => {
        props.setBreadcrumbItems('List', breadcrumbItems)
       
    })

    useEffect(() => {
        getCounts()
    }, []);



    function getLocations() {

        let urlPath = "DynamicPage/entry-locations/"

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then((responseData) => {

                // console.log(responseData);

                if (responseData.status) {
                    state.locations = responseData.data
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    // this.setState({ loading: false });
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                // return this.setState({ isInvitingUser: false })
            });

    }


    function getCounts(filter = false) {
        // getReportDashboard
        setLoading(true)
        let urlPath = "appmanager/dashboard/"

        const startDate = state.selectionRange.startDate;
        const endDate = state.selectionRange.endDate;

        fetch(url.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                filter: filter,
                startDate: startDate,
                endDate: endDate
            })
        }).then((response) => response.json())
            .then((responseData) => {

                // console.log(responseData);
               
                if (responseData.status) {
                    // {
                    //     "status": true,
                    //     "data": {
                    //         "totalUsers": 2202,
                    //         "mainApp": 1929,
                    //         "gbnApp": 10,
                    //         "sangathanApp": 155,
                    //         "eventUsers": 858
                    //     }
                    // }

                    state.counts = {
                        totalUsers: responseData.data.totalUsers,
                        mainApp: responseData.data.mainApp,
                        gbnApp: responseData.data.gbnApp,
                        sangathanApp: responseData.data.sangathanApp,
                        eventUsers: responseData.data.eventUsers,
                        locationUsers: responseData.data.totalLocationsUsers,
                    };
                    state.locations = responseData.data.locations
                    seteventList(responseData.data.eventList)



                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                }
                setLoading(false)
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {

            });

    }




    function applyFilter() {

        const startDate = new Date(state.selectionRange.startDate);
        const endDate = new Date(state.selectionRange.endDate);
        let filter = false;
        if (startDate < endDate) {
            filter = true
            // console.log(`${startDate} is earlier than ${endDate}`);
        } else if (startDate > endDate) {
            filter = true
            // console.log(`${startDate} is later than ${endDate}`);
        } else {
            filter = false
            console.log(`${startDate} is the same as ${endDate}`);
        }


        // console.log("Date ",this.state.selectionRange,filter,startDate,endDate)
        state.showDatePicker = false
        getCounts(filter)
    }





    function redirect() {
        if (state.isRedirect) {
            //return <Navigate to={'/'} />
        }
    }

    function handleSelect(ranges) {
        console.log(ranges);
        state.selectionRange = ranges.selection
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
    }
    function handleDeleteClick(e, event) {
        const confirmDelete = window.confirm("Are you sure you want to delete this event?");
        if (confirmDelete) {
            // Delete the item or perform the desired action
            state.deleteItemId = event._id
            onCheckDelete(e, event._id)

            console.log("Item deleted.");
        } else {
            console.log("Deletion canceled.");
        }
    };
    function onCheckDelete(e, event) {
        e.preventDefault()

        // const { signOut } = this.context;
        setLoading(true)
        state.itemDeleteLoading = true

        let urlPath = "event/DeleteEvent/" + event;


        // this.setState({ loading: false });
        // return


        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then(async (responseData) => {
                if (responseData.status) {
                    alert(
                        "Event Removed Successfully.")
                    getCounts()

                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")

                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                }
                setLoading(false)
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )

            }).finally(final => {

            });
    }

    const formattedEventList = eventList.map(event => ({
        ...event,
        registrationStartDate: [moment(event.registrationStartDate).format("DD MMM YYYY"), <br />, " to ", <br />, moment(event.registrationEndDate).format("DD MMM YYYY")], // format as you wish
        eventStartDate: [moment(event.eventStartDate).format("DD MMM YYYY"), <br />, " to ", <br />, moment(event.eventEndDate).format("DD MMM YYYY")],
        city: event.city != undefined ? [event.city, ",", <br />, event.state, ",", <br />, event.country] : "",
        RegLink: [<a data-toggle="tooltip" title='Copy Registration Link' className="btn btn-warning btn-sm copylink " onClick={() => { navigator.clipboard.writeText(state.RegistrationLinkToCopy + `${event._id}`) }}>
            <span className=" menu-icon fas fa fa-clone"></span></a>],
        EventLink: [<a data-toggle="tooltip" title='Copy Event Attendant Link' className="btn btn-warning btn-sm copylink" onClick={() => { navigator.clipboard.writeText(state.EventAttendantLinkToCopy + `${event._id}`) }}>
            <span className=" menu-icon fas fa fa-clone"></span> </a>],
        Action: [

            <Link to={`/EventUsers/${event._id}`} className="btn btn-info btn-sm m-1 " >
                <span className="menu-text" style={{ color: 'white' }}>
                    <span className=" menu-icon fas fa fa-eye" style={{ color: 'white' }}>
                    </span>
                </span>
            </Link>,
            <a href={`/Event/${event._id}`} data-toggle="tooltip" title='Edit Event' className="btn btn-warning btn-sm m-1 ">
                <span className="menu-text" style={{ color: 'white' }}>
                    <span className=" menu-icon fas fa fa-pen" style={{ color: 'white' }}>
                    </span>
                </span>
            </a>,

            <button type="button" className="btn btn-danger btn-sm  m-1" onClick={(e) => { handleDeleteClick(e, event) }}>
                <span className=" menu-icon fas fa fa-trash" style={{ color: 'white' }}>
                </span></button>
        ]
    }));
    const tableData = {
        columns: [
            {
                label: "Event Name",
                field: "eventName",
                sort: "asc",
                width: 23,
            },
            {
                label: 'Reg. Date',
                width: 13,
                sort: "asc",
                field: "registrationStartDate"//[moment(registrationStartDate).format("DD MMM YYYY"), <br />, " to ", <br />, moment(registrationEndDate).format("DD MMM YYYY")]
            },
            {
                label: 'Event Date',
                width: 13,
                sort: "asc",
                field: "eventStartDate"
            },
            {
                label: 'Location',
                width: 13,
                sort: "asc",
                field: "city"
            },
            {
                label: 'Reg. Link',
                width: 10,
                sort: "asc",
                field: "RegLink"
            },
            {
                label: 'Attnd. Link',
                width: 10,
                sort: "asc",
                field: "EventLink"
            },
            {
                label: 'Action',
                width: 18,
                sort: "asc",
                field: "Action"
            }
        ],
        rows: formattedEventList,
    }

 

    return (

        <React.Fragment>

            {isLoading ? <Spinners /> :
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardHeader>
                                <div class="row col-12">
                                    <div class="col-10">
                                        <CardTitle className="h4 mb-0">Event List</CardTitle>
                                    </div>
                                    <div class="col-2 ">
                                        <Link to={'/Event'} className="btn btn-danger  h5 " >
                                            <span className="menu-text" style={{ color: 'white' }}>Add Event</span>
                                        </Link>
                                    </div>
                                </div>

                            </CardHeader>
                            <CardBody>

                                <br />
                                <div class="row">

                                    <MDBDataTable responsive striped bordered data={tableData} />
                                </div>

                            </CardBody>

                        </Card>
                    </Col>

                </Row>

            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(EventList);