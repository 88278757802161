import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { connect } from "react-redux";
import Select from "react-select"
import { setBreadcrumbItems } from "../../store/actions";

import Spinners from "components/Common/Spinner"
import { Value } from "sass";
import * as url from "../../helpers/url_helper"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { string } from "prop-types";
import { MDBDataTable } from "mdbreact"
import { Link } from 'react-router-dom';
import moment from 'moment';
const data = {

    loading: true,
    errors: {},
    ResErrors: {},
    RoleList: [],
    sangathanList: [],
    isRedirect: false,
    sangathanId: null,
    dropdownData: [],
    sangathanName: "",
    selectedSangathan: {
        label: null,
        name: null,
        _id: null,
        parentId: null,
        options: [],
    },
    currentEditItem: {},
    itemLoading: false,
    itemDeleteLoading: false,
    deleteItemId: null,
    moveItemSelected: {},
    isPrepareMove: false,
    isMoving: false,
    moveTargetItem: {},
    token: 'Bearer SECRET',
    errors: {},
};
const SangathanCreate = (props) => {
    document.title = "Sangathan List - VUF";
    const [Values, setValues] = useState(data);
    const breadcrumbItems = [
        { title: "VUF", link: "#" },
        { title: "SangathanList", link: "#" }
    ]

    const [isLoading, setLoading] = useState(false)
    const [sangathanList, setsangathanList] = useState([])
    useEffect(() => {
        props.setBreadcrumbItems('List', breadcrumbItems)

    })

    useEffect(() => {

        _getUserCred();
        // Perform your componentDidMount logic here


    }, []);

    function _getUserCred() {
         let user = localStorage.getItem('user');
        user = JSON.parse(user);
        // const User = user;

        const role = localStorage.getItem('role');

        let state = user;
        let sangathanId = state?.sangathanId || "";
        const Role = state.role || "Member";
        setValues((prevState) => ({
            ...prevState,
            user:state,
        role:state.role,
        isMember : (!state.role || state.role?.toLowerCase() == "member" || false),
        sangathanId :sangathanId,
        token:localStorage.getItem('token'),
        loading: true
    }));
        onGetSangathanList()
        // const { state, signOut } = this.context;

    }


    function onGetSangathanList(id = null, rootIndex = 0) {
        setLoading(true)
        let urlPath = "sangathan/get-all-sangathan-list"

        fetch(url.url + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Values.token
            },
            // body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                if (responseData.status && 'response' in responseData) {
                    // console.log("SANGATHANS ",responseData?.response?.sangathanList)

                    setsangathanList(responseData?.response?.sangathanList || [])

                } else if (!responseData.status && "auth" in responseData) {
                    // AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    console.log("Something went wrong")

                } else {
                    console.log("Something went wrong")
                }
                setLoading(false)
            }).catch(error => {
                console.log("err", error);

            }).finally(final => {
            });
    }


    /**
     *
     * @param null
     * @description This function validate inputs
     * @returns boolean
     */
    function handleValidation(sangathan) {
        let errors = {};
        let formIsValid = true;
        const stringRegex = /^[A-Za-z]{3,}$/;
        const charRegex = /.{3,}/;

        if (Values.sangathanName?.trim() === "") {
            formIsValid = false;
            errors.sangathanName = "Please enter sangathan name";
        }

        if (!charRegex.test(Values.sangathanName)) {
            formIsValid = false;
            // errors.sangathanName = "Please enter only string with minimum 3 characters";
            errors.sangathanName = "Please enter minimum 3 characters";
        }

        if (sangathan.name == Values.sangathanName) {
            formIsValid = false;
            errors.sangathanName = "You have not made any changes";
        }



        Values.errors = errors;

        return formIsValid;

        // if (!this.handleValidation()) {
        //     return false
        // }
    }


    function onRenameSangathan(e, sangathan) {
        e.preventDefault()

        // const { signOut } = this.context;



        if (!this.handleValidation(sangathan)) {
            return false
        }

        const Body = JSON.stringify({
            _id: Values.currentEditItem?._id,
            name: Values.sangathanName.trim()
        })

        let urlPath = "sangathan/rename-sangathan";

        // console.log("rename sangathannn =>", Constants.url + urlPath, token, Body)

        // this.setState({ loading: false });
        // return


        fetch(url.url + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Values.token
            },
            body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                if (responseData.status && 'response' in responseData) {

                    alert(
                        "Sangathan Renamed Successfully.")
                    Values.sangathanName = ""
                    Values.currentEditItem = {}
                    setsangathanList(responseData?.response?.sangathanList || [])
                    // this.redirectWithPath("/sangathan-create")
                } else if (!responseData.status && "auth" in responseData) {
                    // AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")

                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )

            }).finally(final => {

            });
    }


    function onCheckDeleteSangathan(e, sangathan) {
        e.preventDefault()

        // const { signOut } = this.context;

        const Body = JSON.stringify({
            _id: sangathan?._id
        })

        let urlPath = "sangathan/check-delete-sangathan";


        fetch(url.url + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Values.token
            },
            body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                if (responseData.status && 'response' in responseData) {

                    if (responseData.response.readyToDelete) {
                        onDeleteSangathan(e, sangathan)
                    } else {
                        alert(
                            'Something went wrong, Please try again after sometime.'
                        )

                    }

                    // this.redirectWithPath("/sangathan-create")
                } else if (!responseData.status && "auth" in responseData) {
                    //AuthController.Logout();
                } else if (!responseData.status && responseData.error && responseData.linkedChild) {

                    if (responseData.linkedChild.length > 0) {
                        console.log("DATA ", responseData)
                        let list = ''
                        responseData.linkedChild.map((s, i) => {
                            list += "- " + s?.name + "\n" || '-' + '\n ';
                        })

                        if (responseData.type == "HAS_MEMBER") {

                            alert(
                                `${responseData?.error} \n\n ChildSangathans:\n${list}` || "Error")

                        } else {

                            const confirmDelete = window.confirm(`${responseData?.error} \n\n ChildSangathans:\n${list}` || "Error");
                            if (confirmDelete) {
                                // Delete the item or perform the desired action
                                console.log("Item deleted.");
                                onDeleteSangathan(e, sangathan)
                            } else {
                                console.log("Deletion canceled.");
                            }
                        }

                    }

                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")

                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )

            }).finally(final => {
                Values.deleteItemId = null
            });
    }


    // DIRECT API TO REMOVE SANGATHAN BUT DO NOT CALL BEFORE CHECK DELETE API
    function onDeleteSangathan(e, sangathan) {
        e.preventDefault()

        // const { signOut } = this.context;


        Values.itemDeleteLoading = true

        const Body = JSON.stringify({
            _id: sangathan?._id
        })

        let urlPath = "sangathan/delete-sangathan";

        // this.setState({ loading: false });
        // return


        fetch(url.url + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Values.token
            },
            body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                if (responseData.status && 'response' in responseData) {

                    alert(
                        "Sangathan Removed Successfully.")
                    Values.sangathanName = ""
                    Values.currentEditItem = {}
                    setsangathanList(responseData?.response?.sangathanList || [])
                    // this.redirectWithPath("/sangathan-create")
                } else if (!responseData.status && "auth" in responseData) {
                    // AuthController.Logout();
                } else if (!responseData.status && responseData.error && responseData.linkedChild) {

                    if (responseData.linkedChild.length > 0) {
                        console.log("DATA ", responseData)
                        if (responseData.type == "HAS_MEMBER") {
                            alert(
                                responseData?.error || "Error")

                        } else {
                            let list = ''
                            responseData.linkedChild.map((s, i) => {
                                list += "- " + s?.name || '-' + '\n ';
                            })

                            const confirmDelete = window.confirm(`${responseData?.error} \n\n ChildSangathans:\n${list}` || "Error");
                            if (confirmDelete) {
                                // Delete the item or perform the desired action
                                console.log("Item deleted.");
                            } else {
                                console.log("Deletion canceled.");
                            }
                        }

                    }


                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")

                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )

            }).finally(final => {
                Values.deleteItemId = null
            });
    }



    function onCancelEdit(sangathan) {
        Values.currentEditItem = {}
        Values.sangathanName = ''
        Values.errors = {}
    }
    function onSetEdit(sangathan) {

        Values.currentEditItem = sangathan
        Values.sangathanName = sangathan.name
    }

    function handleDeleteClick(e, sangathan) {
        const confirmDelete = window.confirm("Are you sure you want to delete this sangathan?");
        if (confirmDelete) {
            // Delete the item or perform the desired action
            Values.deleteItemId = sangathan._id
            onCheckDeleteSangathan(e, sangathan)
            // this.onDeleteSangathan(e,sangathan)
            console.log("Item deleted.");
        } else {
            console.log("Deletion canceled.");
        }
    };

    function setMoveItem(e, sangathan) {
        Values.moveItemSelected = sangathan
        Values.isPrepareMove = true
    }

    function cancelMove(e, sangathan) {
        Values.moveItemSelected = {}
        Values.isPrepareMove = false
    }

    function confirmTarget(e, sangathan) {


        const confirmMove = window.confirm(`Move this ${Values.moveItemSelected.name.toUpperCase()} to ${sangathan.name.toUpperCase()} with its users, You can not undo this action, Are you sure?`);
        if (confirmMove) {

            setTargetItem(e, sangathan)

        } else {
            console.log("move canceled.");
            Values.isMoving = false
            Values.moveItemSelected = {}
            Values.isPrepareMove = false
            Values.moveTargetItem = {}
        }

    }

    function setTargetItem(e, sangathan) {
        console.log(`Moving ${Values.moveItemSelected.name} to ${sangathan.name}`)

        Values.isMoving = true
        Values.moveTargetItem = sangathan

        onMoveSangathan(sangathan)
        // setTimeout(() => {
        //     this.setState({ isMoving : false, moveItemSelected : {}, isPrepareMove : false, moveTargetItem : {}  })
        // }, 3000)

    }

    function onMoveSangathan(targetSangathan) {


        // const { signOut } = this.context;


        if (!Values.moveItemSelected?._id && !targetSangathan?._id) {
            return false
        }


        const Body = JSON.stringify({
            sourceId: Values.moveItemSelected?._id,
            targetId: targetSangathan?._id
        })

        let urlPath = "sangathan/move-sangathan";


        fetch(url.url + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': Values.token
            },
            body: Body,
        }).then((response) => response.json())
            .then(async (responseData) => {
                if (responseData.status && 'response' in responseData) {
                    setTimeout(async () => {
                        alert(
                            "Sangathan Moved Successfully.")
                        Values.isMoving = false
                        Values.moveItemSelected = {}
                        Values.isPrepareMove = false
                        Values.moveTargetItem = {}
                        setsangathanList (responseData?.response?.sangathanList || [])
                    }, 2000)
                } else if (!responseData.status && "auth" in responseData) {
                    //AuthController.Logout();
                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")
                    Values.isMoving = false
                    Values.moveItemSelected = {}
                    Values.isPrepareMove = false
                    Values.moveTargetItem = {}
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                    Values.isMoving = false
                    Values.moveItemSelected = {}
                    Values.isPrepareMove = false
                    Values.moveTargetItem = {}
                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                Values.isMoving = false
                Values.moveItemSelected = {}
                Values.isPrepareMove = false
                Values.moveTargetItem = {}
            }).finally(final => {
                // return this.setState({ isMoving : false, moveItemSelected : {}, isPrepareMove : false, moveTargetItem : {}  })
            });
    }

    const formattedList = sangathanList.map(data => ({
        ...data,
        sangathanName: [Values.currentEditItem?._id == data._id ?
            <>
                <input type="text" name="sangathanName" className="form-control form-control" placeholder={data?.name || 'Sangathan name'} value={Values.sangathanName} />
                <span className="form-text text-danger">{Values.errors?.sangathanName}</span>
            </>
            :
            <input type="text" name="s" className="form-control-plaintext form-control" style={{ outline: 'none', paddingLeft: '1rem', paddingRight: '1rem', border: 'none' }} value={data?.name || '-'} /> || '-'
        ],
        Action:
            [<>
                {
                    data._id == Values.deleteItemId ?
                        <button type="button" className="btn btn-danger mr-2" onClick={(event) => false}>Deleting...</button>
                        :
                        <button type="button" className="btn btn-danger mr-2" onClick={(event) => { handleDeleteClick(event, data) }}>Delete</button>
                }
                {
                    !Values.isPrepareMove ?
                        <button type="button" className="btn btn-warning mr-2" onClick={(event) => { setMoveItem(event, data) }}>Move</button>
                        :
                        data._id == Values.moveItemSelected?._id ?
                            Values.isMoving ?
                                <button type="button" className="btn btn-warning mr-2" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center' }} onClick={(event) => false}>
                                    <span className="svg-icon menu-icon fas fa-arrow-left" style={{ marginTop: 5 }} />
                                    {/* Moving */}
                                    {/* <Dots /> */}
                                </button>
                                :
                                <button type="button" className="btn btn-default mr-2" onClick={(event) => cancelMove(event, data)}>Cancel Move</button>
                            :
                            Values.isMoving ?
                                data._id == Values.moveTargetItem._id ?
                                    <button type="button" className="btn btn-default mr-2" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center' }} onClick={(event) => false}>
                                        {/* <Dots /> */}
                                        <span className="svg-icon menu-icon fas fa-arrow-right" style={{ marginTop: 5 }} />
                                        {/* Moving Here */}
                                        {/* <Dots /> */}
                                    </button>
                                    :
                                    <button type="button" className="btn btn-light mr-2" onClick={(event) => false}><span className="svg-icon menu-icon fas fa-ban" />Wait</button>
                                :
                                <button type="button" className="btn btn-warning mr-2" onClick={(event) => { confirmTarget(event, data) }}>Move To Here</button>
                }
                {Values.currentEditItem?._id == data._id ?
                    <>
                        {
                            Values.itemLoading ?
                                <button type="button" className="btn btn-success mr-2">Renaming...</button>
                                :
                                <button type="button" className="btn btn-success mr-2" onClick={(event) => { onRenameSangathan(event, data) }}>Save</button>}
                        <button type="button" className="btn btn-default mr-2" onClick={(event) => { onCancelEdit() }}>Cancel</button>
                    </>
                    :
                    <button type="button" className="btn btn-primary mr-2" onClick={(event) => { onSetEdit(data) }}>Rename</button>
                }
            </>]

    }));


    const tableData = {
        columns: [
            {
                label: "Name",
                field: "sangathanName",
                sort: "asc",
                width: 23,
            },
            {
                label: 'Users',
                width: 13,
                sort: "asc",
                field: "userCount"
            },
            {
                label: 'Action',
                width: 13,
                sort: "asc",
                field: "Action"
            },

        ],
        rows: formattedList,

    };


    return (

        <React.Fragment>

            {isLoading ? <Spinners /> :
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md="12">
                                        <CardTitle className="h4 mb-0">Sangathan List</CardTitle>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>

                                <br />
                                <div class="row">

                                    <MDBDataTable responsive striped bordered data={tableData} />
                                </div>

                            </CardBody>

                        </Card>
                    </Col>

                </Row>

            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(SangathanCreate);

