import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../store/actions";

import Spinners from "components/Common/Spinner"
import { Value } from "sass";
import * as url from "../../helpers/url_helper"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { string } from "prop-types";


const EventDashboard = (props) => {
  document.title = "Dashboard - VUF";

  const breadcrumbItems = [
    { title: "VUF", link: "#" },
    { title: "Dashboard", link: "#" }
  ]

  const Values = {

    locations: [],
    counts: {
      totalUsers: 0,
      mainApp: 0,
      gbnApp: 0,
      sangathanApp: 0,
      eventUsers: 0,
      locationUsers: 0,
    },
    selectionRange: {
      startDate: new Date(),
      endDate: new Date(),
    },
    dates:[],
    eventList: [],
    showDatePicker: false,
  };

  const [isLoading, setLoading] = useState(true)
  const [form, setValues] = useState(Values)

  useEffect(() => {
    props.setBreadcrumbItems('List', breadcrumbItems)

  })

  useEffect(() => {
    
    fetchData();

    // Perform your componentDidMount logic here
    console.log('Component has mounted');

  }, []);
  async function fetchData(filter = false) {

    let urlPath = "appmanager/dashboard/"

    const startDate =Values.selectionRange.startDate;
    const endDate = Values.selectionRange.endDate;

    fetch(url.VUF_APP_API + urlPath, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filter: filter,
        startDate: startDate,
        endDate: endDate
      })
    }).then((response) => response.json())
      .then((responseData) => {


        if (responseData.status) {

          let formValues = {
            counts: {
              totalUsers: responseData.data.totalUsers,
              mainApp: responseData.data.mainApp,
              gbnApp: responseData.data.gbnApp,
              sangathanApp: responseData.data.sangathanApp,
              eventUsers: responseData.data.eventUsers,
              locationUsers: responseData.data.totalLocationsUsers,
            },
            locations: responseData.data.locations,
            eventList: responseData.data.eventList
          };
          setValues(formValues);
          setLoading(false)
        } else {
          alert(
            'Something went wrong, Please try again after sometime.'
          )

        }
      }).catch(error => {

        alert(
          'Something went wrong, Please try again after sometime.'
        )
        // this.setState({ loading: false });
      }).finally(final => {
        setLoading(false)
      });

  }

  function dateval(val){
    if(val.length==2){
      Values.selectionRange.startDate=val[0]
      Values.selectionRange.endDate=val[1]
      fetchData(true);
    }else{
      fetchData(false);
    }
    
   
  }


  return (
    <React.Fragment>

      {isLoading ? <Spinners /> :
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="12">
                    <CardTitle className="h4 mb-0">Dashboard</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div class="row">
                  <div class="col-sm-4">
                  <Label>Date Range</Label>
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="Select the date range"
                    onChange={(e) => dateval(e) }
                    options={{
                      mode: "range",
                      dateFormat: "d-m-Y"
                    }}
                  />
                  </div>
                </div>
                <br />
                <div class="row">

                  <div class="col-sm-6 col-xl-4">
                    <div class="mini-stat bg-primary card">
                      <div class="card-body mini-stat-img card-body">
                        <div class="mini-stat-icon">
                          <i class="float-end mdi mdi-cube-outline"></i>
                        </div>
                        <div class="text-white">
                          <h6 class="text-uppercase mb-3 font-size-16 text-white">Total Users</h6>
                          <h2 class="mb-4 text-white">{form.counts.totalUsers}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xl-4">
                    <div class="mini-stat bg-primary card">
                      <div class="card-body mini-stat-img card-body">
                        <div class="mini-stat-icon">
                          <i class="float-end mdi mdi-buffer"></i>
                        </div>
                        <div class="text-white">
                          <h6 class="text-uppercase mb-3 font-size-16 text-white">Event Users</h6>
                          <h2 class="mb-4 text-white">{form.counts.eventUsers}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xl-4">
                    <div class="mini-stat bg-primary card">
                      <div class="card-body mini-stat-img card-body">
                        <div class="mini-stat-icon">
                          <i class="float-end mdi mdi-tag-text-outline"></i>
                        </div>
                        <div class="text-white">
                          <h6 class="text-uppercase mb-3 font-size-16 text-white">All Locations Users</h6>
                          <h2 class="mb-4 text-white">{form.counts.locationUsers}</h2>
                          {/* <span class="ms-2">From previous period</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xl-4">
                    <div class="mini-stat bg-primary card">
                      <div class="card-body mini-stat-img card-body">
                        <div class="mini-stat-icon">
                          <i class="float-end mdi mdi-briefcase-check"></i>
                        </div><div class="text-white">
                          <h6 class="text-uppercase mb-3 font-size-16 text-white">VUF Main App</h6>
                          <h2 class="mb-4 text-white">{form.counts.mainApp}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xl-4">
                    <div class="mini-stat bg-primary card">
                      <div class="card-body mini-stat-img card-body">
                        <div class="mini-stat-icon">
                          <i class="float-end mdi mdi-briefcase-check"></i>
                        </div><div class="text-white">
                          <h6 class="text-uppercase mb-3 font-size-16 text-white">VUF GBN App</h6>
                          <h2 class="mb-4 text-white">{form.counts.gbnApp}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xl-4">
                    <div class="mini-stat bg-primary card">
                      <div class="card-body mini-stat-img card-body">
                        <div class="mini-stat-icon">
                          <i class="float-end mdi mdi-briefcase-check"></i>
                        </div><div class="text-white">
                          <h6 class="text-uppercase mb-3 font-size-16 text-white">VUF Sangathan</h6>
                          <h2 class="mb-4 text-white">{form.counts.sangathanApp}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Col xl="12">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col md="12">
                          <CardTitle className="h4 mb-0">Locations</CardTitle>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div class="row">
                        {form.locations.length > 0 ?

                          form.locations.map((loc, i) => {
                            return (
                              <div class="col-md-6">
                                <div class="card card-body">
                                  <div class="hstack gap-3 ">
                                    <div>
                                      <div class="text-muted">Location Name</div>
                                      <div class="h4 ">{loc.name}</div>
                                    </div>
                                    <div class="ms-auto">
                                      <div class="text-muted">Users</div>
                                      <div class="h4 ">{loc.count}</div>
                                    </div>

                                  </div>

                                </div>
                              </div>

                              // <div className="col-md-6" style={{ marginTop: 20 }}>
                              //   <div className="col-md-12" style={{ justifyContent: 'space-between', alignItems: 'center', backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "50px", borderRadius: 5, padding: '15px 20px', display: 'flex' }}>
                              //     <div style={{ display: 'flex', flexDirection: 'column', }}>
                              //       <span className="" style={{ fontSize: "15px", color: '#ededed', marginBottom: 0 }}>Location Name</span>
                              //       <span className="" style={{ fontSize: "26px", color: 'white', marginBottom: 0, fontWeight: '700' }}>{loc.name}</span>
                              //     </div>
                              //     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                              //       <span className="" style={{ fontSize: "15px", color: '#ededed', marginBottom: 0 }}>Users</span>
                              //       <span className="" style={{ fontSize: "26px", color: 'white', marginBottom: 0, fontWeight: '700' }}>{loc.count}</span>
                              //     </div>


                              //   </div>
                              // </div>

                            )
                          })
                          :
                          <div className="col-md-12">
                            <div class="card card-body">
                              <div class="h4 ">No Locations</div>
                            </div>
                          </div>

                        }

                      </div>
                    </CardBody>

                  </Card>
                </Col>
                <Col xl="12">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col md="12">
                          <CardTitle className="h4 mb-0">Events</CardTitle>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div class="row">
                        {form.eventList.length > 0 ?

                          form.eventList.map((event, i) => {
                            return (
                              <div class="col-md-6">
                                <div class="card card-body">
                                  <div class="hstack gap-3 ">
                                    <div>
                                      <div class="text-muted">Event Name</div>
                                      <div class="h4 ">{event.eventName}</div>
                                    </div>
                                    <div class="ms-auto">
                                      <div class="text-muted">Users</div>
                                      <div class="h4 ">{event.count}</div>
                                    </div>

                                  </div>

                                </div>
                              </div>


                              // <div className="col-md-6" style={{ marginTop: 20 }}>
                              //   <div className="col-md-12" style={{ justifyContent: 'space-between', alignItems: 'center', backgroundImage: " linear-gradient(to right,#D73C34, #B32A61,#90178E)", minHeight: "50px", borderRadius: 5, padding: '15px 20px', display: 'flex' }}>
                              //     <div style={{ display: 'flex', flexDirection: 'column', }}>
                              //       <span className="" style={{ fontSize: "15px", color: '#ededed', marginBottom: 0 }}>Event Name</span>
                              //       <span className="" style={{ fontSize: "26px", color: 'white', marginBottom: 0, fontWeight: '700' }}>{event.eventName}</span>
                              //     </div>
                              //     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                              //       <span className="" style={{ fontSize: "15px", color: '#ededed', marginBottom: 0 }}>Users</span>
                              //       <span className="" style={{ fontSize: "26px", color: 'white', marginBottom: 0, fontWeight: '700' }}>{event.count}</span>
                              //     </div>


                              //   </div>
                              // </div>
                            )
                          })
                          :
                          <div className="col-md-12">
                            <div class="card card-body">
                              <div class="h4 ">No Events</div>
                            </div>
                          </div>
                        }


                      </div>
                    </CardBody>

                  </Card>
                </Col>

              </CardBody>

            </Card>
          </Col>

        </Row>

      }

    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(EventDashboard);

