import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Button, Input, FormFeedback, InputGroup, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import { connect } from "react-redux";
import Select from "react-select"
import { setBreadcrumbItems } from "../../store/actions";

import Spinners from "components/Common/Spinner"
import { Value } from "sass";
import * as url from "../../helpers/url_helper"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { string } from "prop-types";
import { MDBDataTable } from "mdbreact"
import { Link } from 'react-router-dom';
import moment from 'moment';

const LocationList = (props) => {
    document.title = "Location List - VUF";
    const [isLoading, setLoading] = useState(true)
    const breadcrumbItems = [
        { title: "VUF", link: "#" },
        { title: "LocationList", link: "#" }
    ]

    const [LocationList, setLocationList] = useState([]);
    const state = {
        isLoading: false,
        errors: {},
        ResErrors: {},
        isRedirect: false,
        sangathanId: null,
        country_code: '+91',
        ccode: "+91",
        email: "",
        mobileNo: "",
        remark: "",
        assignRole: "",
        firstName: "",
        lastName: "",
        country: "",
        state: "",
        city: "",
        RegistrationLinkToCopy: url.CurrentUrl+ "register/",
        QRCodeValue: "ABCD12345",
        locations: [],
        counts: {
            totalUsers: 0,
            mainApp: 0,
            gbnApp: 0,
            sangathanApp: 0,
            eventUsers: 0,
            locationUsers: 0,
        },
        selectionRange: {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },

        showDatePicker: false,
    };



    useEffect(() => {
        props.setBreadcrumbItems('List', breadcrumbItems)
    })
    useEffect(() => {
        getCounts()
    }, []);
    function getCounts(filter = false){
        // getReportDashboard
        setLoading(true)
        let urlPath = "appmanager/dashboard/"

        const startDate = state.selectionRange.startDate;
        const endDate = state.selectionRange.endDate;

        fetch(url.VUF_APP_API + urlPath, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                filter: filter,
                startDate: startDate,
                endDate: endDate
            })
        }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.status) {
                    setLocationList(responseData.data.locations)
                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )
                }
                setLoading(false)
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )
                // this.setState({ loading: false });
            }).finally(final => {
                
            });

    }




    function handleDeleteClick(e, event) {
        const confirmDelete = window.confirm("Are you sure you want to delete this location?");
        if (confirmDelete) {
            // Delete the item or perform the desired action
            state.deleteItemId = event._id
            onCheckDelete(e, event._id)

            console.log("Item deleted.");
        } else {
            console.log("Deletion canceled.");
        }
    };
    function onCheckDelete(e, location) {
        e.preventDefault()

        // const { signOut } = this.context;

        state.isLoading = true
        state.itemDeleteLoading = true

        let urlPath = "location/DeleteLocation/" + location;

        fetch(url.VUF_APP_API + urlPath, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }).then((response) => response.json())
            .then(async (responseData) => {
                if (responseData.status) {
                    alert(
                        "Location Removed Successfully.")
                    getCounts()

                } else if (!responseData.status && responseData.error) {
                    alert(
                        responseData?.error || "Error")

                } else {
                    alert(
                        'Something went wrong, Please try again after sometime.'
                    )

                }
            }).catch(error => {
                console.log("err", error);
                alert(
                    'Something went wrong, Please try again after sometime.'
                )

            }).finally(final => {

            });
    }

    const formattedEventList = LocationList.map(location => ({
        ...location,
        city: location.city != undefined ? [location.city, ",", location.state, ",", location.country] : "",
        RegLink: [<a data-toggle="tooltip" title='Copy Registration Link' className="btn btn-warning btn-sm m-1 copylink " onClick={() => { navigator.clipboard.writeText(state.RegistrationLinkToCopy + `${location._id}`) }}>
            <span className=" menu-icon fas fa fa-clone"></span></a>],
        Action: [<a href={`/LocationUsers/${location.place_id}`} className="btn btn-info btn-sm m-1 ">
            <span className="menu-text" style={{ color: 'white' }}>
                <span className=" menu-icon fas fa fa-eye" style={{ color: 'white' }}>
                </span>
            </span>
        </a>,
        <a href={`/LocationAdd/${location._id}`} data-toggle="tooltip" title='Edit Location' className="btn btn-warning btn-sm m-1 ">
            <span className="menu-text" style={{ color: 'white' }}>
                <span className=" menu-icon fas fa fa-pen" style={{ color: 'white' }}>
                </span>
            </span>
        </a>,
        <button type="button" className="btn btn-danger btn-sm m-1" onClick={(event) => { handleDeleteClick(event, location) }}>
            <span className=" menu-icon fas fa fa-trash" style={{ color: 'white' }}>
            </span></button>
        ]
    }));
    const tableData = {
        columns: [
            {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 23,
            },
            
            {
                label: 'Address',
                width: 25,
                sort: "asc",
                field: "city"
            },
            {
                label: 'Reg. Link',
                width: 10,
                sort: "asc",
                field: "RegLink"
            },
           
            {
                label: 'Action',
                width: 18,
                sort: "asc",
                field: "Action"
            }
        ],
        rows: formattedEventList,
    }

 

    return (

        <React.Fragment>

            {isLoading ? <Spinners /> :
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardHeader>
                                <div class="row col-12">
                                    <div class="col-10">
                                        <CardTitle className="h4 mb-0">Location List</CardTitle>
                                    </div>
                                    <div class="col-2 ">
                                        <Link to={'/LocationAdd'} className="btn btn-danger  h5 " >
                                            <span className="menu-text" style={{ color: 'white' }}>Add Location</span>
                                        </Link>
                                    </div>
                                </div>

                            </CardHeader>
                            <CardBody>

                                <br />
                                <div class="row">

                                    <MDBDataTable responsive striped bordered data={tableData} />
                                </div>

                            </CardBody>

                        </Card>
                    </Col>

                </Row>

            }

        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(LocationList);